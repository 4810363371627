import { useHistory } from "react-router-dom";

export function ordinalSuffixOf(i) {
  let j = i % 10,
      k = i % 100
  if (j === 1 && k !== 11) {
    return i + 'st'
  }
  if (j === 2 && k !== 12) {
    return i + 'nd'
  }
  if (j === 3 && k !== 13) {
    return i + 'rd'
  }
  return i + 'th'
}

export function getScoreColor(score){
  if( score == 'N/A' ){
    return  'text-dark'
  }

  if( score == 0 ){
    return 'text-success';
  }
  else if( score <= 3 ){
    return 'text-warning';
  }
  else if( score <= 7 ){
    return 'text-rating-orange';
  }

  return 'text-danger';
}

export function getScoreColorValue(score){
  if( score == 'N/A' ){
    return  '#262626'
  }
  else if( score == 0 ){
    return '#28A745';
  }
  else if( score <= 3 ){
    return '#FFC107';
  }
  else if( score <= 7 ){
    return '#E67F23';
  }

  return '#DC3545';
}

export function getScoreColorCompetency(score){
  if( score == 'N/A' ){
    return  'text-dark'
  }

  if( score >= 8 ){
    return 'text-success';
  }
  else if( score >= 4 ){
    return 'text-warning';
  }
  return 'text-danger';
}

export function getScoreColorValueCompetency(score){
  if( score == 'N/A' ){
    return  '#262626'
  }

  if( score >= 8 ){
    return '#28A745';
  }
  else if( score >= 4 ){
    return '#FFC107';
  }

  return '#DC3545';
}

export function getSectionScore(assessment, percentage){
  if (assessment?.assessment_template?.assessment_template_type === 2) {

    let total_score = assessment.score
    let out_of = assessment.out_of

    return percentage ? Math.round(total_score / out_of * 100) :  total_score / out_of;
  }
  return assessment.score
}

export function getAnswer(answers, groupIndex, question){

  let answer = answers.find(_ => _.question_id === question.id)
  if( question.select_multiple ){
    answer = answers.filter(_ => _.question_id === question.id)
  }
  if (groupIndex && answers) {
    if( question.is_other ){
      let answerList = answers.filter(_ => _.question_id === question.id);
      if( answerList &&  answerList[groupIndex]){
        answer =  answerList[groupIndex];
      }
    }
    else{
      answer = answers.find(_ => _.question_id === question.id && _.question_index === groupIndex)
    }
  }
  if (answer) {
    if (!question.select_multiple && answer.question_choice_id != null ) {
      let choice = question.question_choices.find(_ => _.id == answer.question_choice_id)
      if (choice) {
        return {
          choice,
          answer
        }
      }
    }
    else if( question.select_multiple ){
      let choice = question.question_choices.filter(_ => answer.map(_=> _.question_choice_id).includes( _.id))
      return  {
        choice,
        answer
      }
    }
    return answer
  }
  return null
}

export function getValidationErrors(error) {
  let validationErrors = [];
  if (error.response.status === 422) {
    for (const key in error.response.data.errors) {
      validationErrors.push(
        error.response.data.errors[key][0]
      )
    }
  }
  return validationErrors
}