const Users = {
  title: 'Users',
  update_token: 'Update Token',
  labels: {
    organization: 'Organization(s)',
    subscription: 'Subscription',
    subscription_plan: 'Plan',
  },
  subscription_status: {
    inactive: 'Inactive',
    active: 'Active',
    all: 'All',
  },
  dialogs: {
    titles: {
      token_updated: 'Tokens updated!'
    }
  },
  buttons: {
    remove_promotion: 'Remove Promotion',
    remove_promotion_in_network: 'Promote in Network',
  }
}
export default Users