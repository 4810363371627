const MyAccount = {
  title: 'My Account',
  licence_details: 'License Details',
  organization_details: 'Organization Details',
  logo_uploads: 'Logo Uploads',
  business_listing: 'My Business Listing',
  promote_listing: 'Promote Listing',
  promote_listing_message: 'If you’d like to promote your listing on the ‘Our Network’ page, please contact us below.',
  page_public_details: 'Public Page Details',
  assessor_details: 'Assessor Details',
  organization_branches: 'Organization Branches',
  subscription_details: 'Subscription Details',
  branch_users: 'Branch Users',
  services: 'Services',
  buttons: {
    update_details: 'Update Details',
    save_organization_details: 'Save Organization Details',
    save_business_listing: 'Save My Business Listing',
    add_new_branch: 'Add New Branch',
    add_new_user: 'Add New User',
    add_new_company: 'Add New Company',
    remove_company: 'Remove Company',
    remove_service: 'Remove Service',
    add_new_service: 'Add mew Service',
    refresh: 'Refresh',
  },
  update_company_logo: 'Update Company Logo',
  update_accreditation_logo: 'Update Accreditation Logo (If Required)',
  logo_minimum_size: 'Minimum size: 400 x 400px',
  signature_minimum_size: 'Minimum size: 800 x 200px',
  user_signature_for_certification: 'User\'s Signature for Certificate',
  rates: {
    per_hour: 'Per Hour',
    per_day: 'Per Day',
  },
  notifications: {
    titles: {
      promotion_request_sent: 'Your Promotion request was sent',
      business_listing_missing: 'Business listing Missing',
    },
    body: {
      promotion_request_sent: 'Please wait and we will contact you for more information',
      business_listing_missing: 'We couldn\'t find your business listing, please add business listing to promote',
    }
  }
}
export default MyAccount