import {Redirect} from "react-router-dom";
import React from "react";
import LOCATION from "@/constants/Location";
import { useAuth } from '../services/Auth'

function Dashboard(props) {
  const auth = useAuth();
    return (
        <Redirect
            to={{
                pathname: auth.user.admin ? LOCATION.ASSESSMENT_TEMPLATES.LIST.path : LOCATION.ASSESSMENTS.LIST.path,
                state: {from: props.location}
            }}
        />
    )
}
export default Dashboard;
