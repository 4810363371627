import Chart from 'react-apexcharts'

function AssessmentScoreIcon ({ score, size, suffix, value, color, hollowSize }) {

  const data = {

    series: [value],
    options: {
      legend: {
        display: false,
        show: false,
      },
      chart: {
        height: size,
        width: size,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: hollowSize ?? '50%',
          },
          track: {
            background: '#E2DDDB',
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              formatter: (val) => {
                return suffix ? `${score}${suffix}` : score
              },
              offsetY: 7,
              fontSize: (size / 7) + 'px',
              fontWeight: 'bold',
            }
          }
        },
      },
      fill: {
        type: 'solid',
        colors: [color]

      },
      stroke: {
        lineCap: 'round'
      },
      labels: ['90%'],
    },
  }

  return (
    <Chart
      options={data.options}
      series={data.series}
      type="radialBar"
      height={size}
      width={size}
    />
    // <div className="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
  )
}

export default AssessmentScoreIcon