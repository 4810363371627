import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import LoadingCover from '@/components/LoadingCover'
import LOCATION from '@/constants/Location'
import { useAuth } from '@/services/Auth'
import ConfirmModal from '@/components/modals/ConfirmModal'
import { Button, Col, Form, Row } from 'react-bootstrap'
import LoadingButton from '@/components/LoadingButton'
import CompanyForm from './Forms/CompanyForm'
import Plus from '@/components/icons/Plus'

function BusinessListing () {

  const { t } = useTranslation()
  const auth = useAuth()

  const companyFields = {
    name: null,
    description: null,
    external_page_url: null,
    services: [
      {
        name: null,
        price: null,
        rate: null,
      }
    ],
  }

  const [companies, setCompanies] = useState([companyFields])
  const [updatingDetails, setUpdatingDetails] = useState(false)
  const [loading, setLoading] = useState(true)

  const [showSuccess, setShowSuccess] = useState(false)
  const [title, setTitle] = useState('')

  const serviceSchema = {
    name: yup.string()
      .required(t('common.form_validation.is_required', { attribute: t('common.form.service_name') })),
    price: yup.string()
      .required(t('common.form_validation.is_required', { attribute: t('common.form.service_price') })),
    rate: yup.string()
      .required(t('common.form_validation.is_required', { attribute: t('common.form.rate') })),
  }

  const schema = yup.object().shape({
    companies: yup.array().of(yup.object().shape({
      name: yup.string()
        .required(t('common.form_validation.is_required', { attribute: t('common.form.company_name') })),
      description: yup.string()
        .required(t('common.form_validation.is_required', { attribute: t('common.form.company_description') })),
      external_page_url: yup.string()
        .url(t('common.form_validation.invalid_url'))
        .required(t('common.form_validation.is_required', { attribute: t('common.form.external_page_url') })),
      services: yup.array().of(yup.object().shape({
        ...serviceSchema,
      })),
    }))
  })

  const {
    handleSubmit,
    register,
    unregister,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: companies,
    resolver: yupResolver(schema),
  })

  const onSave = (values) => {
    setUpdatingDetails(true)

    let request = auth.postRequest(`${LOCATION.COMPANIES.API.path}`, values)

    request
      .then(response => {
        getCompanies()
        setUpdatingDetails(false)
        setShowSuccess(true)
        setTitle(t('common.dialogs.titles.companies_updated'))
      })
      .catch(error => {
        setUpdatingDetails(false)
      })
  }

  const getCompanies = () => {
    setLoading(true)
    let request = auth.getRequest(`${LOCATION.COMPANIES.API.path}`)

    request
      .then(response => {
        if (response.data && response.data.length !== 0) {
          setCompanies(response.data)
          reset({
            companies: response.data
          })
        }
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })

    return request
  }

  const handleConfirm = () => {
    setShowSuccess(false)
    setTitle(null)
  }

  const addCompany = () => {
    let companiesList = [].concat(companies)
    companiesList.push(companyFields)
    setCompanies(() => companiesList)
  }

  const removeCompany = (indexToRemove) => {
    let companiesList = companies.filter( (_, index) => index !== indexToRemove);
    setCompanies(() => companiesList)
    unregister(`companies[${indexToRemove}]`);
  }

  useEffect(() => {
    let request = getCompanies()
    return () => request?.abort && request.abort()
  }, [])

  if (loading) {
    return <LoadingCover/>
  }

  return (
    <>
      <ConfirmModal
        show={showSuccess}
        title={title}
        confirmText={t('common.dialogs.actions.okay')}
        handleConfirm={handleConfirm}
      />

      <Form noValidate onSubmit={handleSubmit(onSave)} className="row">
        <Row className="mt-2">
          <h5 className="">{t('pages.my_account.page_public_details')}</h5>
        </Row>
        {
          companies.map((company, index) =>
            <Row className="">
              <CompanyForm
                key={index}
                companyIndex={index}
                company={company}
                errors={errors?.companies}
                register={register}
                unregister={unregister}
                namePrefix={`companies[${index}]`}
              />
             <Col className="col-12 mt-3">
               {
                 companies.length > 1 ?
                   <Button className="px-0" variant="link" onClick={() => removeCompany(index)}>
                     <span className="me-1">{t('pages.my_account.buttons.remove_company')}</span>
                   </Button>: null
               }
               <hr className="my-2"/>
             </Col>
            </Row>
          )
        }
        <Form.Group className="d-grid gap-2 mt-4">
          <Button variant="success" onClick={() => addCompany()}>
            <span className="me-1">{t('pages.my_account.buttons.add_new_company')}</span>
            <Plus/>
          </Button>
        </Form.Group>
        <Row className="mt-2">
          <Form.Group className="d-grid gap-2 mt-4">
            <LoadingButton
              type="submit"
              className="text-white mt-4"
              titleTranslationKey="pages.my_account.buttons.save_business_listing"
              loading={updatingDetails}
            />
          </Form.Group>
        </Row>
      </Form>
    </>
  )
}

export default BusinessListing