import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react'
import LoadingCover from '@/components/LoadingCover'
import PageActionSection from '@/components/PageActionSection'
import BackButton from '../../components/Buttons/BackButton'
import DownloadButton from '../../components/Buttons/DownloadButton'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'
import moment from 'moment'
import AssessmentScoreIcon from '../../components/AssessmentScoreIcon'
import { getScoreColorValue, getSectionScore, getScoreColorValueCompetency } from '@/Utility'

function Certificate(){
  const { t } = useTranslation()
  const auth = useAuth()
  let { id } = useParams()

  const certificateRef = useRef(null);
  const [loading, setLoading] = useState(true)
  const [assessment, setAssessment] = useState(null)

  useEffect(() => {
    let request = auth.getRequest(`${LOCATION.ASSESSMENTS.API.path}/${id}`)
    request
      .then(response => {
        setAssessment(response.data)
        setLoading(false)
      })
      .catch(error => {
      })

    return () => request?.abort && request.abort()

  }, [])

  if (loading || !assessment) {
    return (
      <LoadingCover/>
    )
  }

  return (
    <>
      <PageActionSection
        prepend={[<BackButton fillColor="white"/>]}
        append={[
          <DownloadButton
            fileName={assessment?.assessment_template.title}
            element=".competency-certificate"
            width={630}
            height={446}
            orientation="l"
          />
        ]}
        title={t('pages.competency_templates.buttons.competency_certificate')}
        type="primary"
      />
      <div id="competency-certificate-container" className="mt-4 px-auto container report-container ">
        <div id="competency-certificate" className="position-relative " ref={certificateRef}>
          <div className="competency-certificate text-center">
            <img src="/assets/certificate/competency-certificate.png" className="certificate-background"/>
            <div className="name">{ auth.user.name }</div>
            <div className="date">{ moment(assessment.created_at).format(t('common.formats.date_no_time'))}</div>
            <div className="assessment-score" id="assessment-score">
              <AssessmentScoreIcon
                color={getScoreColorValueCompetency(getSectionScore(assessment, false) * 10)}
                score={getSectionScore(assessment, true)}
                size={150}
                suffix="%"
                value={getSectionScore(assessment, true)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Certificate;