const AssessmentAudit = {
  title: 'Assessment Audit',
  automated_assessments_for_audit: 'Automated Assessments for Audit ({{progress}} submitted)',
  requested_assessments: 'Requested Assessments',
  request_audit_instruction: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit  Egestas ultrices scelerisque odio lacus, neque rhoncus. Quis aliquam duis ac suspendisse massa commodo pellentesque ultricies arcu.',
  payments: {
    title: 'Payment for assessment audit for {{attribute}}'
  },
  dialogs: {
    titles: {
      recommendation_saved: 'Your Recommendation is saved',
    }
  }
}
export default AssessmentAudit