import { Editor } from '@tinymce/tinymce-react'

function TinyMceEditor({
  value,
  onEditorChange,
  initialValue,
}){
  return (
    <Editor
      apiKey={process.env.REACT_APP_TINY_MCE_KEY}
      value={value}
      onEditorChange={(e) => onEditorChange(e)}
      initialValue={initialValue}
    />
  )
}
export default TinyMceEditor;