import { Button } from 'react-bootstrap'
import React from 'react'
import Download from '@/components/icons/Download'
import { useTranslation } from 'react-i18next'
import html2canvas from 'html2canvas'
import jsPdf from 'jspdf'

import * as htmlToImage from 'html-to-image'
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image'

function DownloadButton ({ element, fileName, width, height, orientation, multiplePages, scale }) {

  const { t } = useTranslation()

  const downloadPDF = () => {

    const doc = new jsPdf({
      format: 'a4',
      unit: 'px',
      orientation: orientation ?? 'p',
    })

    multiplePages ? loadMultiplePages(doc) : loadSinglePage(doc);

  }

  const loadSinglePage =(doc) => {
    const elems = [element]

    elems.forEach((elem, ind) => {
      const node = document.querySelector(elem)
      htmlToImage.toPng(node)
        .then(function (dataUrl) {
          let img = new Image()
          img.src = dataUrl

          doc.addImage(img, 'png', 0, 0, width, height)

          doc.save(fileName)
        })
        .catch(function (error) {
          // console.log(error)
        })
    })
  }

  const loadMultiplePages = (doc) => {
    var htmlElement = document.getElementById(element);
    // you need to load html2canvas (and dompurify if you pass a string to html)
    const opt = {
      callback: function (jsPdf) {
        jsPdf.save(fileName);
        // to open the generated PDF in browser window
        // window.open(jsPdf.output('bloburl'));
      },
      margin: [0,0,0,0],
      autoPaging: 'text',
      html2canvas: {
        allowTaint: true,
        dpi: 300,
        letterRendering: true,
        logging: false,
        scale: scale ?? 1
      }
    };

    doc.html(htmlElement, opt);
  }
  return (
    <Button variant="light" size="sm" className="text-primary" onClick={() => downloadPDF()}>
      <span className="me-1">{t('common.download')}</span>
      <Download/>
    </Button>
  )
}

export default DownloadButton