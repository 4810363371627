import { Button, Form } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ServiceForm from './ServiceForm'
import Plus from '@/components/icons/Plus'

function CompanyForm ({
  company,
  companyIndex,
  errors,
  register,
  namePrefix,
  unregister,
}) {

  const {t} = useTranslation();

  const serviceFields = {
    name: null,
    price: null,
    rate: null,
  }

  const [services, setServices] = useState([]);

  const addService =() => {
    let serviceList = [].concat(services);
    serviceList.push(serviceFields);
    setServices(() => serviceList);

  }
  const removeService =(indexToRemove) => {
    let serviceList = services.filter( (_, index) => index !== indexToRemove);
    setServices(() => serviceList);
    unregister(`${namePrefix}.services[${indexToRemove}]`);
  }

  useEffect(() => {
    if(  company?.services ){
      setServices(company.services);
    }
  }, [company])

  return (
    <>
      <Form.Group className="col col-md-6 col-12 mt-3" controlId="formBasicCompanyName">
        <Form.Label>
          {t('common.form.company_name')}
        </Form.Label>
        <Form.Control
          placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.company_name') })}
          {...register(`${namePrefix}.name`)}
          isInvalid={!!(errors && errors[companyIndex] && errors[companyIndex].name)}
        />

        <Form.Control.Feedback type="invalid">
          {errors && errors[companyIndex] &&  errors[companyIndex].name && errors[companyIndex].name.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="col col-12 mt-3" controlId="formBasicRegistrationNumber">
        <Form.Label>
          {t('common.form.company_description')}
        </Form.Label>
        <Form.Control
          as="textarea"
          placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.company_description') })}
          {...register(`${namePrefix}.description`)}
          isInvalid={!!(errors && errors[companyIndex] && errors[companyIndex].description)}
        />

        <Form.Control.Feedback type="invalid">
          {errors && errors[companyIndex] &&  errors[companyIndex].description && errors[companyIndex].description.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="col col-md-6 col-12 mt-3" controlId="formBasicWebsite">
        <Form.Label>
          {t('common.form.external_page_url')}
        </Form.Label>
        <Form.Control
          placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.external_page_url') })}
          {...register(`${namePrefix}.external_page_url`)}
          isInvalid={!!(errors && errors[companyIndex] && errors[companyIndex].external_page_url)}
        />

        <Form.Control.Feedback type="invalid">
          {errors && errors[companyIndex] &&  errors[companyIndex].external_page_url && errors[companyIndex].external_page_url.message}
        </Form.Control.Feedback>
      </Form.Group>

      <h5 className="mt-4">{t('pages.my_account.services')}</h5>

      {
        services.map((service, index) =>
          <ServiceForm
            services={services}
            key={index}
            serviceIndex={index}
            errors={errors && errors[companyIndex] && errors[companyIndex].services }
            register={register}
            namePrefix={`${namePrefix}.services[${index}]`}
            removeService={removeService}
          />
        )
      }

      <Form.Group className="d-grid gap-2 mt-4">
        <Button onClick={() => addService()}>
          <span className="me-1">{t('pages.my_account.buttons.add_new_service')}</span>
          <Plus/>
        </Button>
      </Form.Group>
    </>
  )
}

export default CompanyForm