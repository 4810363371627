import { Card } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import React from 'react'
import CircleCheck from '@/components/icons/CircleCheck'
import { useEffect } from 'react'
import LOCATION from '@/constants/Location'
import { useAuth } from '@/services/Auth'
import { useTranslation } from 'react-i18next'

function AuditRequestSuccess (props) {

  const { t } = useTranslation()
  const auth = useAuth()

  const { id } = useParams()
  const query = new URLSearchParams(props.location.search)

  const payment_intent = query.get('payment_intent')

  useEffect(() => {
    let request = auth.postRequest(`${LOCATION.ASSESSMENTS.LIST.path}/audit-request-paid/${id}`, {
      payment_intent,
    })
    request
      .then(response => {
      })
      .catch(error => {
      })

    return () => request?.abort && request.abort()

  }, [])

  return (
    <div className="container request-sent d-flex justify-content-center align-items-center h-100">
      <Card className="border-0">
        <Card.Body className="text-center">
          <CircleCheck/>
          <h3 className="mt-4">{t('pages.assessments.audit.request_sent')}</h3>
          <p className="my-4">{t('pages.assessments.audit.assessor_will_be_in_touch', { hours: 48 })}</p>
          <Link className="btn btn-primary text-white" to={`${LOCATION.ASSESSMENTS.LIST.path}`}>
            {t('pages.assessments.buttons.back_to_my_assessments')}
          </Link>

        </Card.Body>

      </Card>
    </div>
  )
}

export default AuditRequestSuccess