import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useAuth } from '@/services/Auth'
import LoadingCover from '@/components/LoadingCover'
import UserFilters from './components/UserFilters'

import Table from '@/views/components/Tables/Users'
import LOCATION from '@/constants/Location'

function UsersIndex () {
  const history = useHistory()
  const route = useLocation()
  const auth = useAuth()
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [assessmentTemplates, setAssessmentTemplates] = useState([])

  const [query, setQuery] = useState(route.state && route.state.query || { page: 0, skip: 0, limit: 15 })

  const [searching, setSearching] = useState(false)
  const [exporting, setExporting] = useState(false)

  const requestExport = (values) => {
    setExporting(true)
    auth.getRequest(`${LOCATION.USERS.API.path}/export`, values)
      .then(response => {

        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'users.csv'
        link.click()

        setExporting(false)
      })
      .catch(error => {
      })
  }

  useEffect(() => {
    history.replace(
      route.pathname,
      {
        ...route.state,

        query,
      }
    )
  }, [query])

  useEffect(() => {
    let request = auth.getRequest(LOCATION.ASSESSMENT_TEMPLATES.API.path)

    request
      .then(response => {
        setAssessmentTemplates(response.data.data)
        setLoading(false)
      })
      .catch(error => {
      })

    return () => request?.abort && request.abort()

  }, [])

  if (loading) return <LoadingCover/>

  return (
    <div className="content">
      <div className="section-header d-flex justify-content-between mb-4">
        <h4>{t('pages.users.title')}</h4>
      </div>
      <UserFilters
        searching={searching}
        values={query}
        loadUsers={setQuery}
        exporting={exporting}
        requestExport={requestExport}
      />
      <div className="my-4">
        <Table query={query} showPagination={true} onData={_ => setSearching(!_)}/>
      </div>
    </div>
  )
}

export default UsersIndex