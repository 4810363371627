const AssessmentTemplates = {
  title: 'Assessment Templates',
  create_new_assessment: 'Create New Template',
  new_assessment_template: 'New Assessment Template',
  user_input: 'User Own score',
  dialogs: {
    titles: {
      template_created: 'Assessment Template Created!',
      template_updated: 'Assessment Template Updated!',
      confirm_heading: 'Are You Sure?',
    },
    body: {
      remove_question: 'Do You Want To Remove Question?',
      delete_section: 'Do You Want To Remove this Section?',
    }
  },
  buttons: {
    collapse_questions_and_reorder: 'Collapse Questions & Reorder',
    expand_questions: 'Expand Questions',
  }
}
export default AssessmentTemplates