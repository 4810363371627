import { Button, Card, Form, Image } from 'react-bootstrap'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'
import LoadingCover from '@/components/LoadingCover'
import ConfirmModal from '@/components/modals/ConfirmModal'
import LoadingButton from '@/components/LoadingButton'

function AssessmentTemplateFormBuilder ({ assessment, requestSave, saveContinue, submitFormRef, saveDraft, validationErrors }) {

  const { t } = useTranslation()
  const auth = useAuth()
  const baseUrl = auth.api.defaults.baseURL

  const [imageUrl, setImageUrl] = useState(null)
  const [uploadingImage, setUploadingImage] = useState(false)

  const [showDialog, setShowDialog] = useState(false)
  const [dialogTitle, setDialogTitle] = useState(null)
  const [dialogBody, setDialogBody] = useState(null)
  const [confirmText, setConfirmText] = useState(t('common.dialogs.actions.okay'))
  const [cancelText, setCancelText] = useState(null)

  const [deletingAssessment, setDeletingAssessment] = useState(false)
  const [assessmentDeleted, setAssessmentDeleted] = useState(false)

  const schema = yup.object().shape({
    title: yup.string()
      .required(t('common.form_validation.is_required', { attribute: t('common.form.title') })),
    description: yup.string()
      .required(t('common.form_validation.is_required', { attribute: t('common.form.description') })),
    featured_image: assessment?.id ? yup.string().nullable() : yup.string()
      .required('common.form_validation.is_required', { attribute: t('common.form.featured_image') }),
  })

  const {
          handleSubmit,
          register,
          setValue,
          resetField,
          formState: { errors },
        } = useForm({
    defaultValues: (_ => {
      return {
        title: assessment?.title,
        description: assessment?.description,
      }
    })(),
    resolver: yupResolver(schema),
  })

  const onSave = (values) => {
    requestSave(values)
  }

  const uploadImage = (image) => {
    setUploadingImage(true)
    let imageData = image.target.files[0]
    let form = new FormData()
    form.append('image', imageData)

    auth.postRequest(`${LOCATION.ASSESSMENT_TEMPLATES.API.path}/upload-image`, form)
      .then(response => {
        setValue('featured_image', response.data)
        setImageUrl(response.data)
        setUploadingImage(false)
      })
      .catch(error => {
      })
  }

  const deleteAssessment = () => {
    setDeletingAssessment(true)
    auth.deleteRequest(`${LOCATION.ASSESSMENT_TEMPLATES.API.path}/${assessment.id}`)
      .then(response => {
        setDialogTitle(t('pages.assessments.notifications.assessment_deleted.title'))
        setDialogBody(null)
        setConfirmText(t('common.okay'))
        setShowDialog(true)
        setDeletingAssessment(false)
        setAssessmentDeleted(true)
      })
      .catch(error => {
        setDeletingAssessment(false)
      })
  }

  const requestDeleteAssessment = () => {
    setDeletingAssessment(true)
    setDialogTitle(t('pages.assessments.notifications.delete_assessment.title'))
    setDialogBody(t('pages.assessments.notifications.delete_assessment.body', { title: assessment.title }))
    setConfirmText(t('common.buttons.delete'))
    setCancelText(t('pages.assessments.buttons.cancel'))
    setShowDialog(true)
  }
  const handleConfirm = () => {
    if (deletingAssessment) {
      deleteAssessment()
    }
    if (assessmentDeleted) {
      setAssessmentDeleted(false)
      window.location.href = LOCATION.ASSESSMENT_TEMPLATES.LIST.path
    }
    handleCancel()
  }

  const handleCancel = () => {
    setShowDialog(false)
    setConfirmText(null)
    setCancelText(null)
    setDeletingAssessment(false);
  }

  useEffect(() => {
    register('featured_image')
    resetField('featured_image')
  }, [])

  return (
    <>
      <ConfirmModal
        show={showDialog}
        title={dialogTitle}
        body={dialogBody}
        confirmText={confirmText}
        cancelText={cancelText}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
      <Form noValidate onSubmit={handleSubmit(onSave)}>
        <Card className="border-0 radius-0 section-form mx-auto mx-lg-5">
          <Card.Body>
            <div className="d-flex justify-content-between align-items-center">
              <Card.Title>{t('pages.new_assessment.title_and_description')}</Card.Title>
              {
                assessment?.id &&
                <LoadingButton
                  loading={deletingAssessment}
                  onSubmit={requestDeleteAssessment}
                  type="button"
                  titleTranslationKey="common.buttons.delete"
                  variant="danger"
                  className="text-white"
                />
              }
            </div>
            <Form.Group className="mb-4 mt-4" controlId="formBasicTitle">
              <Form.Label>
                {t('common.form.title')}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                placeholder={t('common.form.title_placeholder')}
                {...register('title')}
                isInvalid={!!errors.title}
              />

              <Form.Control.Feedback type="invalid">
                {errors.title && errors.title.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4" controlId="formBasicDescription">
              <Form.Label>
                {t('common.form.description')}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder={t('common.form.description_placeholder')}
                {...register('description')}
                isInvalid={!!errors.description}
                style={{ height: '200px' }}
              />

              <Form.Control.Feedback type="invalid">
                {errors.description && errors.description.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formFile" className="">
              <Form.Label>
                {t('common.form.featured_image')}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="featured_image"
                onChange={(e) => uploadImage(e)}
                type="file"
                accept="image/*,.jpg,.png,.jpeg"
                isInvalid={!!errors.featured_image}
              />
              <p className="small mt-3 mb-0">{t('common.feature_image_size', { size: '720 x 405 px' })}</p>

              <Form.Control.Feedback type="invalid">
                {errors.featured_image && errors.featured_image.message}
              </Form.Control.Feedback>
            </Form.Group>
            {
              uploadingImage &&
              <div className="w-100 mt-2 mb-2" style={{ height: '200px' }}>
                <LoadingCover/>
              </div>
            }
            <div className="w-100 mt-2 mb-2">
              {
                imageUrl &&
                <>
                  <Image className="w-100" src={`${baseUrl}/${imageUrl}`}/>
                </>
              }
            </div>

            {
              (validationErrors.length !== 0) &&
              <div className="form-group mb-2">
                {
                  validationErrors.map((error, index) =>
                    <p className="text-danger font-weight-bold" key={index}>{t(error)}</p>)
                }
              </div>
            }
          </Card.Body>
        </Card>

        <div className="d-flex justify-content-end mt-4 section-form  mx-auto mx-lg-5">
          <Button variant="secondary" size="sm" onClick={() => saveDraft()}>
            <span className="ms-1">{t('pages.new_assessment.save_draft')}</span>
          </Button>
          <Button ref={submitFormRef} variant="success" size="sm" type="submit" className="text-white ms-4">
            <span className="me-1">{t('pages.new_assessment.save_continue')}</span>
          </Button>
        </div>
      </Form>
    </>
  )
}

export default AssessmentTemplateFormBuilder