import LOCATION from '@/constants/Location'
import Assessments from '@/views/pages/AssessmentTemplates'
import Create from '@/views/pages/AssessmentTemplates/Create'
import Edit from '@/views/pages/AssessmentTemplates/Edit'

const AssessmentTemplateRoutes = [
  {
    path: LOCATION.ASSESSMENT_TEMPLATES.LIST.path,
    component: Assessments,
    exact: true,
    translation_key: 'pages.assessment_templates.title',
    main: true,
    gate: 'assessment_templates',
  },
  {
    path: LOCATION.ASSESSMENT_TEMPLATES.CREATE.path,
    component: Create,
    exact: true,
    main: false,
    gate: 'assessment_templates',
  },
  {
    path: LOCATION.ASSESSMENT_TEMPLATES.EDIT.path,
    component: Edit,
    exact: true,
    main: false,
    gate: 'assessment_templates',
  },
]

export default AssessmentTemplateRoutes