import Question from './Question'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import Plus from '@/components/icons/Plus'

function RepeatableQuestionField ({
  question,
  errors,
  register,
  unregister,
  questionIndex,
  inputNameSuffix,
  sectionIndex,
  watch,
  setValue,
  resetField,
  answers,
  auditMode,
}) {

  const { t } = useTranslation()

  const [questionCounter, setQuestionCounter] = useState(1)
  const [questionChildren, setQuestionChildren] = useState([])
  const [groupShowOther, setGroupShowOther] = useState(false)
  const [otherQuestion, setOtherQuestion] = useState(null)
  const [otherQuestions, setOtherQuestions] = useState([])

  const addQuestionRepeat = () => {
    let children = questionChildren
    children.push(question.children)
    setQuestionChildren([].concat(children))
  }

  const removeQuestionRepeat = () => {
    let children = questionChildren

    unregister(`${inputNameSuffix}.children[${children.length - 1}]`)
    children.pop()
    setQuestionChildren([].concat(children))
  }

  const addOtherQuestion =() => {
    let questions = otherQuestions;
    questions.push(otherQuestion)
    setOtherQuestions([].concat(questions))
  }

  useEffect(() => {
    addQuestionRepeat()

    let otherQuestion = question.children.find(_ => _.is_other)
    setOtherQuestion(otherQuestion)
  }, [question])

  useEffect(() => {
    if( otherQuestion && answers ) {
      let otherQuestionAnswers = answers.filter(_ => _.question_id === otherQuestion.id);
      let questions = [];
      otherQuestionAnswers.forEach(_ => {
        questions.push(otherQuestion)
      })
      setOtherQuestions([].concat(questions))
    }

  }, [answers, otherQuestion])

  return (
    <>
      {
        questionChildren.map((children, repeatIndex) => {
          return (
            <div key={repeatIndex}>
              {
                children.map((childQuestion, childIndex) => {
                    if (childQuestion.is_other && !groupShowOther) return null
                    return (
                      <Question
                        key={childIndex}
                        repeatIndex={question.type === 3 ? repeatIndex : null}
                        childQuestion={question}
                        question={childQuestion}
                        errors={errors && errors[questionIndex] && errors[questionIndex].children && errors[questionIndex].children[repeatIndex] ? errors[questionIndex].children[repeatIndex] : {}}
                        register={register}
                        unregister={unregister}
                        index={childIndex}
                        watch={watch}
                        resetField={resetField}
                        inputNameSuffix={`${inputNameSuffix}.children[${repeatIndex}][${childIndex}]`}
                        sectionIndex={sectionIndex}
                        setValue={setValue}
                        answers={answers}
                        auditMode={auditMode}
                      />
                    )
                  }
                )
              }

              {
                otherQuestions.map((otherChildQuestion, OtherIndex) =>
                  <Question
                    key={(OtherIndex + children.length)}
                    repeatIndex={OtherIndex}
                    childQuestion={question}
                    question={otherChildQuestion}
                    errors={errors && errors[questionIndex] && errors[questionIndex].children && errors[questionIndex].children[repeatIndex] ? errors[questionIndex].children[repeatIndex] : {}}
                    register={register}
                    unregister={unregister}
                    index={(OtherIndex + children.length)}
                    watch={watch}
                    resetField={resetField}
                    inputNameSuffix={`${inputNameSuffix}.children[${repeatIndex}][${(children.length + OtherIndex)}]`}
                    sectionIndex={sectionIndex}
                    setValue={setValue}
                    answers={answers}
                    auditMode={auditMode}
                  />
                )
              }
            </div>
          )
        })
      }
      {
        (questionChildren.length > 1 && !auditMode && question.type !== 6) &&

        <Button variant="link" className="p-0 mb-1 mt-3" onClick={removeQuestionRepeat}>
          <span className="text-danger">{t('common.remove', { attribute: question.label })}</span>
        </Button>
      }
      {
        (!auditMode && !question.add_other && question.type !== 6) &&

        <div className="d-grid gap-2 mt-3">
          <Button onClick={addQuestionRepeat}>
            <span className="text-white">{t('common.add', { attribute: question.label })}</span>
            <Plus/>
          </Button>
        </div>
      }
      {
        (question.add_other && otherQuestion) &&

        <div className="d-grid gap-2 mt-3">
          <Button variant="success" onClick={() => addOtherQuestion()}>
            <span className="text-white">{t('common.add', { attribute: otherQuestion.label })}</span>
            <Plus/>
          </Button>
        </div>
      }
    </>
  )

}

export default RepeatableQuestionField