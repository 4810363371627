import { Form } from 'react-bootstrap'
import React from 'react'
import { useTranslation } from 'react-i18next'

function RangeOptions ({
  register,
  inputSuffix,
  errors,
  index,
}) {

  const { t } = useTranslation()

  return (
    <>
      <Form.Group className="my-2 col col-md-6 col-12">
        <Form.Label>
          {t('common.form.min_slider_value')}
          <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.min_slider_value') })}
          {...register(`${inputSuffix}.min_slider_value`)}
          isInvalid={!!(errors && errors[index] && errors[index].min_slider_value)}
        />

        <Form.Control.Feedback type="invalid">
          {errors && errors[index] && errors[index].label && errors[index].min_slider_value.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="my-2 col col-md-6 col-12">
        <Form.Label>
          {t('common.form.max_slider_value')}
          <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.max_slider_value') })}
          {...register(`${inputSuffix}.max_slider_value`)}
          isInvalid={!!(errors && errors[index] && errors[index].max_slider_value)}
        />

        <Form.Control.Feedback type="invalid">
          {errors && errors[index] && errors[index].label && errors[index].max_slider_value.message}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  )
}

export default RangeOptions