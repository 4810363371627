import { useTranslation } from 'react-i18next'
import { useAuth } from '../../services/Auth'
import React, { useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import LOCATION from '@/constants/Location'
import { Form, Row } from 'react-bootstrap'
import CircleCheck from '@/components/icons/CircleCheck'
import { Link, useParams } from 'react-router-dom'
import LoadingButton from '@/components/LoadingButton'

function ResetPasswordForm(){
  const { t } = useTranslation()
  const auth = useAuth()
  const [loading, setLoading] = useState(false)
  const [serverError, setServerError] = useState('')
  const [serverErrors, setServerErrors] = useState([])
  const [passwordReset, setPasswordReset] = useState(false)

  const {token} = useParams();

  let passwordMinLength = 6

  const schema = yup.object().shape({
    email: yup.string()
      .email(t('common.form_validation.invalid_email_address'))
      .required(t('common.form_validation.email_address_is_required')),
    password: yup.string()
      .min(passwordMinLength, t('common.form_validation.password_invalid_min_length', { length: passwordMinLength }))
      .required(t('common.form_validation.password_is_required')),
    password_confirmation: yup.string().oneOf([yup.ref('password'), null], t('common.form_validation.password_is_must_match'))
      .required(t('common.form_validation.password_confirm_required')),
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const signIn = (values) => {
    values.token = token;
    setServerError('')
    setLoading(true)
    auth.postRequest(`${LOCATION.AUTH.FORGOT_PASSWORD.path}/reset`, values)
      .then(response => {
        setLoading(false)
        if (response.data.message === 'OK') {
          setPasswordReset(true)
        }
      })
      .catch(error => {
        setLoading(false)
        if (error?.response?.status === 401) {
          setServerError(error.response.data.message)
        }

        if (error.response.status === 422) {
          let serverErrors = []
          for (const key in error.response.data.errors) {
            serverErrors.push(
              error.response.data.errors[key][0]
            )
          }
          setServerErrors(serverErrors)
        }
      })
  }

  return (
    <div className="row bg-white" style={{ height: '100%' }}>
      <div className="col-sm-8 col-12 border-0 d-flex align-items-center justify-content-center">
        <div className="login-container p-4">
          {
            passwordReset ?
              <Row className="text-center">
                <CircleCheck/>
                <h3 className="mt-4">{t('pages.reset_password.success.title')}</h3>
                <p className="my-4">{t('pages.reset_password.success.description')}</p>
                <Link className="btn btn-primary text-white" to={`${LOCATION.AUTH.SIGN_IN.path}`}>
                  {t('pages.login.title')}
                </Link>
              </Row>

              : <div>
                <Row>
                  <h1 className="mb-5">{t('pages.reset_password.title')}</h1>
                  <p className="mb-5">{t('pages.reset_password.instructions')}</p>
                </Row>
                <Row className="auth-form">
                  <Form noValidate onSubmit={handleSubmit(signIn)}>

                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Label>{t('common.form.email')}</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder={t('common.form.email_placeholder')}
                        {...register('email')}
                        isInvalid={!!errors.email}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.email && errors.email.message}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-4" controlId="formBasicPassword">
                      <Form.Label>{t('common.form.password')}</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder={t('common.form.password')}
                        {...register('password')}
                        isInvalid={!!errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password && errors.password.message}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-4" controlId="formBasicPassword">
                      <Form.Label>{t('common.form.re_enter_password')}</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder={t('common.form.re_enter_password')}
                        {...register('password_confirmation')}
                        isInvalid={!!errors.password_confirmation}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password_confirmation && errors.password_confirmation.message}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {
                      serverError !== '' &&
                      <div className="form-group mb-2">
                        <p className="text-danger py-1 mb-0">{t(serverError)}</p>
                      </div>
                    }
                    {
                      (serverErrors.length !== 0) &&
                      <div className="form-group mb-2">
                        {
                          serverErrors.map((error, index) =>
                            <p className="text-danger font-weight-bold" key={index}>{t(error)}</p>)
                        }
                      </div>
                    }
                    <Form.Group className="">
                      <div className="d-grid gap-2">
                        <LoadingButton
                          className="auth-btn text-white px-5"
                          loading={loading}
                          variant="primary"
                          titleTranslationKey="pages.forgot_password.reset"
                          type="submit"
                        />
                      </div>
                    </Form.Group>

                  </Form>
                </Row>
              </div>
          }
        </div>
      </div>
      <div className="col-4 d-none d-sm-block login-background"></div>
    </div>
  )
}
export default ResetPasswordForm;