import { loadStripe } from '@stripe/stripe-js'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Col, Form, Row } from 'react-bootstrap'

const StripeSetupForm = React.forwardRef(({ errors }, ref) => {
  const { t, i18n } = useTranslation()

  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    if (ref && stripe) {
      ref.current = (values) => {
        return new Promise((resolve, reject) => {
          stripe.createToken(elements.getElement('cardNumber'), { name: values.card_holder_name })
            .then((request) => {
              const payload = { ...values }

              if (request.token) {

                var year = request.token.card.exp_year % 100

                if (year < 10) {
                  year = '0' + '' + year
                }

                var month = request.token.card.exp_month

                if (month < 10) {
                  month = '0' + '' + month
                }

                payload.card_number = request.token.card.last4
                payload.expire = month + '/' + year
                payload.ccv = request.token.id
                payload.card_id = request.token.card.id
                payload.brand = request.token.card.brand

                resolve(payload)
              } else {
                reject(request)
              }
            })
        })
      }
    }

    return () => {
      if (ref) {
        ref.current = null
      }
    }
  }, [stripe])

  if (!stripe || !elements) {
    // Stripe.js has not yet loaded.
    // Make sure to disable form submission until Stripe.js has loaded.
    return <></>
  }

  return (
    <>
      <Form.Group>
        <Form.Label>
          {t('pages.plans.form.card_number')}
        </Form.Label>

        <CardNumberElement className={'form-control w-100 ' + (!!errors.card_number && 'is-invalid' || '')}/>

        <Form.Control.Feedback type="invalid">
          {errors.card_number && errors.card_number.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Row className="mt-3">
        <Col md={6}>
          <Form.Group>
            <Form.Label>
              {t('pages.plans.form.expire')}
            </Form.Label>

            <CardExpiryElement className={'form-control w-100 ' + (!!errors.expire && 'is-invalid' || '')}/>

            <Form.Control.Feedback type="invalid">
              {errors.expire && errors.expire.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col md={6}>
          <Form.Group>
            <Form.Label>
              {t('pages.plans.form.ccv')}
            </Form.Label>

            <CardCvcElement className={'form-control w-100 ' + (!!errors.ccv && 'is-invalid' || '')}/>

            <Form.Control.Feedback type="invalid">
              {errors.ccv && errors.ccv.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

    </>

  )
})
export default StripeSetupForm