import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import '@/assets/scss/app.scss';
import App from './App';
import reportWebVitals from './reportWebVitals'

import './i18n'

import LoadingCover from "@/components/LoadingCover";

var pushState = window.history.pushState;

window.history.pushState = function () {
    var event = new Event('pushState');

    event.state = arguments[0];
    event.title = arguments[1];
    event.url   = arguments[2];

    var result = pushState.apply(this, arguments);

    window.dispatchEvent(event);

    return result;
};

const root = createRoot(document.getElementById('root'));
root.render(
    <Suspense fallback={<LoadingCover/>}>
        <App/>
    </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
