import { Button, Col, Row } from 'react-bootstrap'
import React, { useEffect, useRef, useState } from 'react'
import LOCATION from '@/constants/Location'
import LoadingCover from '@/components/LoadingCover'
import { useAuth } from '@/services/Auth'
import { Link, useHistory, useParams } from 'react-router-dom'
import AssessmentSectionList from '@/views/shared/components/AssessmentSectionList'
import { useTranslation } from 'react-i18next'
import PageActionSection from '@/components/PageActionSection'
import AssessmentSectionForm from './AssessmentSectionForm'
import ArrowRight from '@/components/icons/ArrowRight'
import ArrowLeft from '@/components/icons/ArrowLeft'
import ConfirmModal from '@/components/modals/ConfirmModal'
import { getValidationErrors } from '@/Utility'
import LoadingButton from '@/components/LoadingButton'

function AssessmentForm ({ auditMode }) {

  const { t } = useTranslation()
  const submitFormRef = useRef(null)
  const auth = useAuth()
  const { template_id, assessment_id } = useParams()

  const history = useHistory()

  const [assessmentTemplate, setAssessmentTemplate] = useState(null)
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0)
  const [loading, setLoading] = useState(true)
  const [firstSlide, setFirstSlide] = useState(true)
  const [assessment, setAssessment] = useState(null)
  const [referenceNumber, setReferenceNumber] = useState(null)
  const [address, setAddress] = useState(null)
  const [responseError, setResponseError] = useState(null)
  const [validationErrors, setValidationErrors] = useState([])

  const [maxSectionIndex, setMaxSectionIndex] = useState(0)

  const [saveAsDraft, setSaveAsDraft] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [modalBody, setModalBody] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  const isLastSection = () => {
    return (assessmentTemplate?.assessment_sections.length - 1) === currentSectionIndex
  }

  const saveSection = (values) => {
    if( !submitting ) {
      setSubmitting(true);
      let lastSection = isLastSection()
      values.assessment_template_id = assessmentTemplate.id
      values.assessment_template_section_id = assessmentTemplate.assessment_sections[currentSectionIndex]?.id
      values.current_section_index = currentSectionIndex
      values.last_section = lastSection

      setResponseError(null)

      let request

      if (assessment != null) {
        if (auditMode) {
          request = auth.putRequest(`${LOCATION.ASSESSMENTS.API.path}/audit/${assessment.id}`, values)
        } else {
          request = auth.putRequest(`${LOCATION.ASSESSMENTS.API.path}/${assessment.id}`, values)
        }
      } else {
        request = auth.postRequest(`${LOCATION.ASSESSMENTS.API.path}`, values)
      }

      request
        .then(response => {
          setAssessment(response.data)

          setSubmitting(false);
          if (auditMode) {
            setModalTitle(t('pages.new_assessment.dialogs.titles.audit_saved'))
            setModalBody(null)
          } else if (!lastSection) {
            setModalTitle(t('pages.new_assessment.dialogs.titles.section_saved'))
            setModalBody(t('pages.new_assessment.dialogs.body.section_saved'))
          } else {
            setModalTitle(t('pages.new_assessment.dialogs.titles.assessment_completed'))
            setModalBody(t('pages.new_assessment.dialogs.body.assessment_completed'))
          }
          setShowSuccess(true)
          getAssessment(lastSection)
        })
        .catch(error => {
          if (error?.response && error?.response?.status === 422 && error?.response?.data?.message) {
            setResponseError(error.response.data.message)
          }

          setValidationErrors(getValidationErrors(error));
        })
    }
  }

  const handleConfirm = () => {
    setShowSuccess(false)
    let lastSection = isLastSection()
    setModalTitle(null)
    setModalBody(null)

    if (saveAsDraft) {
      if (auditMode) {
        history.replace(`${LOCATION.ASSESSMENTS_AUDIT.LIST.path}`)
      } else {
        history.replace(`${LOCATION.ASSESSMENTS.LIST.path}`)
      }
    } else {
      if (auditMode) {
        getAssessment(lastSection, false)
      } else if (!lastSection) {
        setCurrentSectionIndex(currentSectionIndex + 1)
        setMaxSectionIndex(currentSectionIndex + 1)
      } else {
        history.replace(`${LOCATION.ASSESSMENTS.LIST.path}/${assessment?.id}/report`)
      }
    }
  }

  const changeSection = (sectionIndex) => {
    setCurrentSectionIndex(sectionIndex)
  }

  const submitSection = (checked) => {
    submitFormRef.current.click()
  }

  const submitSectionAudit = (audit, sectionId) => {

    let lastSection = isLastSection()
    setModalTitle(t('pages.new_assessment.dialogs.titles.processing'))
    setModalBody(t('common.loading'))
    setShowSuccess(true);
    let request = auth.putRequest(`${LOCATION.ASSESSMENTS.API.path}/audit/${assessment_id}`, {
      section_id: sectionId,
    })
    request
      .then(response => {
        setModalTitle(null)
        setModalBody(null)
        setShowSuccess(false);
        getAssessment(lastSection)
      })
      .catch(error => {

      })
  }

  const getAssessment = (lastSection, initial) => {
    if( assessment_id ) {
      let request = auth.getRequest(`${LOCATION.ASSESSMENTS.API.path}/${assessment_id}`)
      request
        .then(response => {
          setAssessment(response.data)
          if (!auditMode) {
            if (initial) {
              if (response.data?.assessment_status === 'completed') {
                setCurrentSectionIndex(response.data.current_section_index)
                setMaxSectionIndex(response.data.assessment_template?.assessment_sections?.length)
              } else {
                setCurrentSectionIndex(response.data.current_section_index + 1)
                setMaxSectionIndex(response.data.current_section_index + 1)
              }
            }
          }
          setReferenceNumber(response.data.fra_reference_number)
          setAssessmentTemplate(response.data.assessment_template)
          setAddress(response.data.address)
          setLoading(false)
        })
        .catch(error => {
        })

      return request
    }
  }

  const saveDraft = () => {
    setSaveAsDraft(true)
    submitFormRef.current.click()
  }

  const clickRef =() => {
    submitFormRef.current.click()
  }
  useEffect(() => {
    if (!auditMode) {
      let request = auth.getRequest(`${LOCATION.ASSESSMENT_TEMPLATES.API.path}/${template_id}`)

      request
        .then(response => {
          setAssessmentTemplate(response.data)
          setLoading(false)
        })
        .catch(error => {
        })

      return () => request?.abort && request.abort()
    }

  }, [])

  useEffect(() => {
    if (assessment_id) {
      let request = getAssessment(false, true)
      return () => request?.abort && request.abort()
    }
  }, [])

  useEffect(() => {
    if (!auditMode && !assessment_id) {
      let request = auth.getRequest(`${LOCATION.ASSESSMENTS.API.path}/reference-number`)

      request
        .then(response => {
          setReferenceNumber(response.data)
        })
        .catch(error => {
        })

      return () => request?.abort && request.abort()
    }

  }, [])

  useEffect(() => {
    if (currentSectionIndex === 0) {
      setFirstSlide(true)
    } else {
      setFirstSlide(false)
    }

  }, [currentSectionIndex])

  if (loading || (assessment_id != null && !assessment)) {
    return (
      <LoadingCover/>
    )
  }

  return (
    <>

      <ConfirmModal
        show={showSuccess}
        title={modalTitle}
        body={modalBody}
        confirmText={t('common.dialogs.actions.okay')}
        handleConfirm={handleConfirm}
      />

      <PageActionSection
        prepend={[
          auditMode ?
            <Link to={`${LOCATION.ASSESSMENTS_AUDIT.LIST.path}`} className="btn btn-primary btn-sm">
              <ArrowLeft/>
              <span className="ms-1">{t('pages.new_assessment.save_draft')}</span>
            </Link>
            :
            <Button variant="dark" size="sm" onClick={() => saveDraft()}>
              <ArrowLeft/>
              <span className="ms-1">{t('pages.new_assessment.save_draft')}</span>
            </Button>
        ]}
        append={
          [
            auditMode ?
              <Link to={`${LOCATION.ASSESSMENTS.LIST.path}/${assessment?.id}/1/report`}
                    className="btn btn-light btn-sm">
                <span className="me-1">{t('pages.assessments.buttons.got_to_ratings_report')}</span>
                <ArrowRight fill="primary"/>
              </Link>
              :
              <LoadingButton
                variant={auditMode ? 'light' : 'success'}
                onSubmit={clickRef}
                size="sm"
                type="button"
                loading={submitting}
                titleTranslationKey={ isLastSection() ? t('pages.assessments.buttons.got_to_ratings_report') : t('pages.assessments.buttons.continue_next_step')}
                icon={<ArrowRight/>}
              />
          ]}
        title={assessmentTemplate.title}
        type={auditMode ? 'primary' : 'dark'}
      />
      <Row>
        <Col className="col-12 col-lg-4">
          <div className="sections-list-panel">
            <div className="d-flex p-3 fw-bold">
              <small>{t('common.ratings')}:</small>
              <small className="mx-1 text-success">{t('common.high')}</small>
              <small className="mx-1 text-warning">{t('common.medium')}</small>
              <small className="mx-1 text-danger">{t('common.low')}</small>
            </div>
            <AssessmentSectionList
              auditMode={auditMode}
              titleSection={false}
              disableOthers={!auditMode}
              changeSection={changeSection}
              submitSection={submitSection}
              submitSectionAudit={submitSectionAudit}
              sections={assessmentTemplate.assessment_sections}
              sectionScores={assessment?.section_scores}
              currentSectionIndex={currentSectionIndex}
              maxSectionIndex={maxSectionIndex}
            />
          </div>
        </Col>
        <Col className="col-12 col-lg-8 p-4">
          {
            assessmentTemplate.assessment_sections.map((section, index) => {
              return index === currentSectionIndex ?
                <AssessmentSectionForm
                  key={section.id}
                  assessment={assessment}
                  assessmentTemplate={assessmentTemplate}
                  answers={assessment?.answers}
                  referenceNumber={referenceNumber}
                  address={address}
                  responsiblePerson={assessment?.responsible_person}
                  firstSlide={firstSlide}
                  lastSection={index + 1 === assessmentTemplate.assessment_sections.length}
                  sectionIndex={index}
                  section={section}
                  auditMode={auditMode}
                  saveSection={saveSection}
                  submitFormRef={submitFormRef}
                  responseError={responseError}
                  validationErrors={validationErrors}
                  saveDraft={saveDraft}
                  submitting={submitting}
                />
                :
                null
            })
          }
        </Col>
      </Row>
    </>
  )
}

export default AssessmentForm