import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import React, { useEffect, useState } from 'react'
import { useAuth } from '@/services/Auth'
import LoadingButton from '@/components/LoadingButton'
import LOCATION from '@/constants/Location'
import moment from 'moment'
import ConfirmModal from '@/components/modals/ConfirmModal'
import CancelSubscription from '@/views/pages/Users/components/CancelSubscription'

function LicenseDetails () {
  const { t } = useTranslation()
  const auth = useAuth()

  const [updatingDetails, setUpdatingDetails] = useState(false)
  const [subscription, setSubscription] = useState(null);

  const [showSuccess, setShowSuccess] = useState(false);
  const [title, setTitle] = useState('');


  const schema = yup.object().shape({
    first_name: yup.string()
      .required(t('common.form_validation.is_required', { attribute: t('common.form.first_name') })),
    last_name: yup.string()
      .required(t('common.form_validation.is_required', { attribute: t('common.form.last_name') })),
    email: yup.string().email()
      .required(t('common.form_validation.is_required', { attribute: t('common.form.email') })),
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: (_ => {
      return {
        first_name: auth.user?.first_name,
        last_name: auth.user?.last_name,
        email: auth.user?.email,
        phone_number: auth.user?.phone_number,
        serial_number: auth.user?.serial_number,
      }
    })(),
    resolver: yupResolver(schema),
  })

  const onSave = (values) => {
    setUpdatingDetails(true)
    auth.putRequest(`${LOCATION.USERS.API.path}/${auth.user.id}`, values)
      .then(response => {
        setShowSuccess(true);
        setUpdatingDetails(false)
        setTitle(t('common.dialogs.titles.update_success'))

      })
      .catch(error => {
        setUpdatingDetails(false)
      })
  }

  const getSubscription = () => {
    let request = auth.getRequest(`${LOCATION.SUBSCRIPTIONS.API.path}/${auth.user.id}`)

    request
      .then(response => {
        setSubscription(response.data)
      })
      .catch(error => {
      })

    return request
  }

  const handleConfirm = () =>{
    setShowSuccess(false);
    setTitle(null);
  }

  useEffect(() => {
    let request = getSubscription()
    return () => request?.abort && request.abort()

  }, [])

  return (
    <>
      <ConfirmModal
        show={showSuccess}
        title={title}
        confirmText={t('common.dialogs.actions.okay')}
        handleConfirm={handleConfirm}
      />

      <Row className="mt-2">
        <Col className="col-12">
          <h5 className="">{t('pages.my_account.assessor_details')}</h5>
          <Form noValidate onSubmit={handleSubmit(onSave)} className="row">
            <Form.Group className="col col-sm-6 col-md-3 col-12 mt-3" controlId="formBasicFirstName">
              <Form.Label>
                {t('common.form.first_name')}
              </Form.Label>
              <Form.Control
                placeholder={t('common.form.first_name_placeholder')}
                {...register('first_name')}
                isInvalid={!!errors.first_name}
              />

              <Form.Control.Feedback type="invalid">
                {errors.first_name && errors.first_name.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col col-sm-6 col-md-3 col-12 mt-3" controlId="formBasicLastName">
              <Form.Label>
                {t('common.form.last_name')}
              </Form.Label>
              <Form.Control
                placeholder={t('common.form.last_name_placeholder')}
                {...register('last_name')}
                isInvalid={!!errors.last_name}
              />

              <Form.Control.Feedback type="invalid">
                {errors.last_name && errors.last_name.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col col-sm-6 col-md-3 col-12 mt-3" controlId="formBasicEmail">
              <Form.Label>
                {t('common.form.email')}
              </Form.Label>
              <Form.Control
                placeholder={t('common.form.email_placeholder')}
                {...register('email')}
                isInvalid={!!errors.email}
              />

              <Form.Control.Feedback type="invalid">
                {errors.email && errors.email.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col col-sm-6 col-md-3 col-12 mt-3" controlId="formBasicPhoneNumber">
              <Form.Label>
                {t('common.form.phone_number')}
              </Form.Label>
              <Form.Control
                placeholder={t('common.form.phone_number_placeholder')}
                {...register('phone_number')}
                isInvalid={!!errors.phone_number}
              />

              <Form.Control.Feedback type="invalid">
                {errors.phone_number && errors.phone_number.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col col-sm-6 col-md-3 col-12 mt-3" controlId="formBasicPhoneNumber">
              <Form.Label>
                {t('common.form.serial_number')}
              </Form.Label>
              <Form.Control
                placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.serial_number') })}
                {...register('serial_number')}
                isInvalid={!!errors.serial_number}
              />

              <Form.Control.Feedback type="invalid">
                {errors.serial_number && errors.serial_number.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col col-sm-6 col-md-3 col-12 mt-4" controlId="formBasicPhoneNumber">
              <div className="d-grid gap-2">
                <LoadingButton
                  type="submit"
                  className="text-white mt-4"
                  titleTranslationKey="pages.my_account.buttons.update_details"
                  loading={updatingDetails}
                />
              </div>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <div className="mt-4 pt-2">
        <h5 className="">{t('pages.my_account.subscription_details')}</h5>

        {
          subscription ?
            <Row>
              <Form.Group className="col col-3 mt-3">
                <Form.Label>
                  {t('common.form.expire_date')}
                </Form.Label>
                <Form.Control
                  disabled={true}
                  value={moment(subscription.expire_at).format(t('common.formats.short_date'))}
                />
              </Form.Group>
              <Form.Group className="col col-3 mt-3">
                <Form.Label>
                  {t('common.form.renew_type')}
                </Form.Label>
                <Form.Control
                  disabled={true}
                  value={t('pages.plans.subscriptions.renew_type')}
                />
              </Form.Group>
              <Form.Group className="col col-3 mt-3">
                <Form.Label>
                  {t('common.form.renew_date')}
                </Form.Label>
                <Form.Control
                  disabled={true}
                  value={t('pages.plans.subscriptions.renew_date_monthly')}
                />
              </Form.Group>
              <Form.Group className="col col-3 mt-3 d-flex align-items-end">
                <div className="d-grid gap-2">
                  <CancelSubscription id={auth.user.id} getSubscription={getSubscription}/>
                </div>
              </Form.Group>
            </Row>
            :
            <h6 className="my-3 text-danger">{t('pages.plans.subscriptions.no_active_subscription')}</h6>
        }
      </div>
    </>
  )
}

export default LicenseDetails