import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import { Button, Col, Row } from 'react-bootstrap'
import LOCATION from '@/constants/Location'
import LoadingButton from '@/components/LoadingButton'

function PromotionButton ({ user }) {

  const auth = useAuth()
  const { t } = useTranslation()

  const [companies, setCompanies] = useState([])
  const [loadingButton, setLoadingButton] = useState(false)

  const listingPromoted = () => {
    let promotedListing = companies.filter(_ => _.promoted)

    return promotedListing.length > 0
  }

  const getBusinessListings = () => {
    let request = auth.getRequest(`${LOCATION.COMPANIES.API.path}?user_id=${user.id}`)

    request
      .then(response => {
        setCompanies(response.data)
      })
      .catch(error => {
      })

    return request
  }

  const removePromotion = () => {
    setLoadingButton(true)
    auth.getRequest(`${LOCATION.COMPANIES.API.path}/remove-promotion`, {
      user_id: user.id
    })
      .then(response => {
        setCompanies(response.data)
        getBusinessListings()
        setLoadingButton(false)
      })
      .catch(error => {
      })
  }

  const promote = () => {
    setLoadingButton(true)
    auth.getRequest(`${LOCATION.COMPANIES.API.path}/promote`, {
      user_id: user.id
    })
      .then(response => {
        setCompanies(response.data)
        getBusinessListings()
        setLoadingButton(false)
      })
      .catch(error => {
      })
  }

  useEffect(() => {
    let request = getBusinessListings()
  }, [])

  if (companies?.length <=0) {
    return <h6 className="text-danger my-3">{t('pages.my_account.notifications.titles.business_listing_missing')}</h6>
  }

  return (
    <Row>
      <Col className="col-12 my-3">
        <div className="d-grid gap-2">
          {
            listingPromoted() ?
              <LoadingButton
                variant="danger"
                onSubmit={removePromotion}
                titleTranslationKey="pages.users.buttons.remove_promotion"
                loading={loadingButton}
              />
              :
              <LoadingButton
                variant="success"
                onSubmit={promote}
                titleTranslationKey="pages.users.buttons.remove_promotion_in_network"
                loading={loadingButton}
              />
          }
        </div>
      </Col>
    </Row>
  )
}

export default PromotionButton