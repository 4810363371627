import { useEffect, useState } from 'react'
import LOCATION from '@/constants/Location'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import LoadingCover from '@/components/LoadingCover'
import { Card, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import React from 'react'
import CircleXMark from '@/components/icons/CircleXMark'
import Paginate from '@/views/components/Paginate'

function OurNetwork () {

  const { t } = useTranslation()
  const auth = useAuth()

  const [promotedListings, setPromotedListing] = useState([])
  const [unPromotedListings, setUnPromotedListing] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(true)

  let perPage = 20

  const getPromotedListing = () => {
    let request = auth.getRequest(`${LOCATION.OUR_NETWORK.API.path}?promoted=1`)

    request
      .then(response => {
        setPromotedListing(response.data.data)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const getUnPromotedListing = () => {
    let request = auth.getRequest(`${LOCATION.OUR_NETWORK.API.path}?promoted=0&take=${perPage}&skip=${page * perPage}`)

    request
      .then(response => {
        setUnPromotedListing(response.data.data)
        setTotalRecords(response.data.length)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const changePage = (event) => {
    setPage(event.selected)
    getUnPromotedListing();
  }

  useEffect(() => {
    getPromotedListing()
  }, [])

  useEffect(() => {
    getUnPromotedListing()
  }, [])

  if (loading) {
    return <LoadingCover/>
  }

  if (promotedListings?.length === 0 && unPromotedListings?.length === 0) {
    return (
      <div className="container request-sent d-flex justify-content-center align-items-center h-100">
        <Card className="border-0">
          <Card.Body className="text-center">
            <CircleXMark/>
            <h3 className="mt-4">{t('common.notifications.listing.no_list_found.title')}</h3>
            <p className="my-4">{t('common.notifications.listing.no_list_found.body')}</p>
            <Link className="btn btn-primary text-white" to={LOCATION.ROOT.path}>
              {t('common.go_home')}
            </Link>
          </Card.Body>
        </Card>
      </div>
    )
  }

  return (
    <div className="our-network">
      <div className="container p-4">
        <Card className="shadow-card border-0 px-4">
          <Card.Body className="p-0">
            {
              promotedListings.map((promotedListing, index) => {
                return (
                  <Row key={promotedListing.id}
                       className={`${promotedListings.length !== index + 1 ? 'border-bottom' : ''} py-3 mx-3`}>
                    <Col className="col-5">
                      <img className="listing-logo" src={promotedListing.logo}/>
                      <span className="mx-3">{promotedListing.name}</span>
                    </Col>
                    <Col className="col-5">{promotedListing.services_list}</Col>
                    <Col className="col-2">
                      <Link className="btn btn-link"
                            to={`${LOCATION.OUR_NETWORK.LIST.path}/${promotedListing.id}`}>{t('common.buttons.view_details')}</Link>
                    </Col>
                  </Row>
                )
              })
            }
          </Card.Body>
        </Card>
        <div className="mt-4">
          {
            unPromotedListings.map((unPromotedListing, index) => {
              return (
                <Row key={unPromotedListing.id}
                     className={`${unPromotedListings.length !== index + 1 ? 'border-bottom' : ''} py-3 mx-3`}>
                  <Col className="col-5">
                    <img className="listing-logo" src={unPromotedListing.logo}/>
                    <span className="mx-3">{unPromotedListing.name}</span>
                  </Col>
                  <Col className="col-5">{unPromotedListing.services_list}</Col>
                  <Col className="col-2">
                    <Link className="btn btn-link"
                          to={`${LOCATION.OUR_NETWORK.LIST.path}/${unPromotedListing.id}`}>{t('common.buttons.view_details')}</Link>
                  </Col>
                </Row>
              )
            })
          }
          <Row>
            <Col className="col-12">
              <Paginate {...{ changePage, totalRecords, page, perPage }} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )

}

export default OurNetwork