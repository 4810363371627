import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import LoadingCover from '@/components/LoadingCover'
import Location from '@/constants/Location'
import { useAuth } from '@/services/Auth'
import PageActionSection from '@/components/PageActionSection'
import BackButton from '@/views/components/Buttons/BackButton'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import AssessmentCertificate from '@/views/components/assessments/AssessmentCertificate'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import LOCATION from '@/constants/Location'
import LoadingButton from '@/components/LoadingButton'

function RequestAudit () {

  const { t } = useTranslation()
  const auth = useAuth()
  let { id, audit } = useParams()
  let history = useHistory()

  const price = 35
  const currency = '£'

  const [loading, setLoading] = useState(true)
  const [assessment, setAssessment] = useState(null)
  const [auditRequestSent, setAuditRequestSent] = useState(false)
  const [requestingAudit, setRequestingAudit] = useState(false)

  const requestAudit = (values) => {
    setRequestingAudit(true);
    values.assessment_id = id;
    auth.postRequest(`${LOCATION.ASSESSMENTS.API.path}/audit-request/${id}`, values)
      .then(response => {
        setAuditRequestSent(true)
        setRequestingAudit(false);
        if(  auth.user.has_free_audit ){
          history.push(`${LOCATION.ASSESSMENTS.LIST.path}`)
        }
        else{
          history.push(`${LOCATION.ASSESSMENTS.LIST.path}/${id}/request-audit/payment`)
        }
      })
      .catch(error => {
      })

  }

  const schema = yup.object().shape({
    audit_requested_message: yup.string().required(t('common.form_validation.is_required', { attribute: t('common.form.message') }))

  })

  const {
    resetField,
    handleSubmit,
    register,
    setValue,
    watch,
    unregister,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    let request = auth.getRequest(`${Location.ASSESSMENTS.API.path}/${id}`)
    request
      .then(response => {
        setAssessment(response.data)
        setLoading(false)
      })
      .catch(error => {
      })

    return () => request?.abort && request.abort()

  }, [])

  if (loading || !assessment) {
    return (
      <LoadingCover/>
    )
  }

  return (
    <>
      <PageActionSection
        prepend={[<BackButton fillColor='white'/>]}
        title={t('pages.assessments.request_audit')}
        type="primary"
      />
      <div className="container audit-request-form">
        <Row>

          <Col className="col-12 col-xl-6 ">
            <AssessmentCertificate assessment={assessment} small={true}/>
          </Col>

          <Col className="col-12 col-xl-6 mt-xl-0 p-xl-2 mt-5 d-flex align-items-center">
            <div className="d-flex flex-column">
              <h3 className="fw-bold mb-3">{t('pages.assessments.request_audit')}</h3>
              {
                auth.user.has_free_audit ?

                  <h3 className="text-primary fw-bold my-3">{t('pages.assessments.free_audit')}</h3>
                  :
                  <h3 className="text-primary fw-bold my-3"> {currency}{price.toFixed(2)} </h3>
              }
              <p className="mt-3 mb-4">{t('pages.assessment_audit.request_audit_instruction')}</p>

              <Form noValidate onSubmit={handleSubmit(requestAudit)}>
                <Form.Group>
                  <Form.Label>
                    {t('common.form.your_message')}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    isInvalid={!!(errors && errors.message)}
                    {...register('audit_requested_message')}
                    placeholder={t('common.form.type_here')}
                  />
                </Form.Group>

                <Form.Control.Feedback type="invalid">
                  {errors.message && errors.message.message}
                </Form.Control.Feedback>

                <div className="d-grid gap-2 my-4 pt-4">
                  <LoadingButton
                    type="submit"
                    className="text-white"
                    loading={requestingAudit}
                    titleTranslationKey={ auth.user.has_free_audit ?
                      t('common.submit')
                      :
                      t('common.buttons.continue_to_payment')}
                  />
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default RequestAudit