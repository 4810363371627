import { Card, Form } from 'react-bootstrap'
import { Elements } from '@stripe/react-stripe-js'
import StripeSetupForm from './StripeSetupForm'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import PaymentMethodInformation from '../components/PaymentMethodInformation'
import LoadingButton from '../../../../components/LoadingButton'

function PaymentMethodForm ({
  processSubmit,
  savePaymentMethod,
  stripePromise,
  stripeSetupFormRef,
  errors,
  loading,
  source,
  makePayment,
  plan,
  assessment,
  AuditPrice,
}) {

  const auth = useAuth()
  const { t } = useTranslation()

  const [paymentMethod, setPaymentMethod] = useState(auth.user.payment_method)

  const requestBuyCredits = () => {
    makePayment({
      plan_id: plan?.id,
      payment_method_id: paymentMethod?.id,
    })
  }

  const requestBuyAudit = () => {
    makePayment({
      assessment_id: assessment?.id,
      payment_method_id: paymentMethod?.id,
    })
  }

  return (
    <div className="container  mt-5" style={{ maxWidth: '600px' }}>
      <Card className="mt-2">
        <Form noValidate onSubmit={processSubmit(savePaymentMethod)}>
          <Card.Body>
            {
              source === 'by-credit' ?

                <Card.Title>{t('pages.payments.trip_method_form')}</Card.Title>
                :
                <Card.Title>{t('pages.payments.payment_method_form')}</Card.Title>
            }
            <div className="my-3">
              {
                auth.user.payment_method ?
                  <PaymentMethodInformation
                    user={auth.user}
                    setPaymentMethod={setPaymentMethod}
                    selectedPaymentMethod={paymentMethod}
                  />
                  :
                  <Elements stripe={stripePromise}>
                    <StripeSetupForm ref={stripeSetupFormRef} errors={errors}/>
                  </Elements>
              }
            </div>
          </Card.Body>
          <Card.Footer className="d-grid gap-2">
            {
              source === 'buy-credits' && auth.user.payment_method && plan ?
                <LoadingButton
                  className="auth-btn text-uppercase fw-bold text-white heading-text"
                  loading={loading}
                  variant="primary"
                  size="lg"
                  onSubmit={requestBuyCredits}
                  titleTranslationKey={t('pages.payments.buttons.buy_one_credit', {
                    price: (plan.price / 100).toFixed(2),
                    currency: auth.user.currency
                  })}
                  type="button"
                />
                :
                source === 'audit-request' && auth.user.payment_method && assessment ?
                  <LoadingButton
                    className="auth-btn text-uppercase fw-bold text-white heading-text"
                    loading={loading}
                    variant="primary"
                    size="lg"
                    onSubmit={requestBuyAudit}
                    titleTranslationKey={t('pages.payments.buttons.request_audit', {
                      price: (AuditPrice / 100).toFixed(2),
                      currency: auth.user.currency
                    })}
                    type="button"
                  />
                  :
                  <LoadingButton
                    className="auth-btn text-uppercase fw-bold text-white heading-text"
                    loading={loading}
                    variant="primary"
                    titleTranslationKey={
                      source === 'buy-trip' ?
                        t('pages.payments.buttons.save_payment_method_and_submit', {
                          price: plan ? (plan.price / 100).toFixed(2) : (AuditPrice / 100).toFixed(2),
                          currency: auth.user.currency
                        })
                        :
                        t('pages.payments.buttons.save_payment_method')
                    }
                    type="submit"
                  />
            }
          </Card.Footer>
        </Form>
      </Card>
    </div>
  )
}

export default PaymentMethodForm