import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { Form } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LOCATION from '@/constants/Location'
import { useAuth } from '@/services/Auth'
import LoadingButton from '@/components/LoadingButton'
import TinyMceEditor from '@/components/TinyMceEditor'
import ConfirmModal from '@/components/modals/ConfirmModal'

function SectionRecommendationForm ({
  assessment,
  sectionScore,
}) {

  const auth = useAuth()
  const { t } = useTranslation()

  const [saving, setSaving] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const modalTitle = t('pages.assessment_audit.dialogs.titles.recommendation_saved')

  const schema = yup.object().shape({
    recommendations: yup.string()
      .required(t('common.form_validation.is_required', { attribute: t('common.form.section_recommendation') })),
  })

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: (_ => {
      return {
        recommendations: sectionScore?.recommendations,
      }
    })(),
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    register('recommendations')
  }, [])

  const recommendations = watch('recommendations')

  const onSave = (values) => {
    setSaving(true)
    let request = auth.putRequest(`${LOCATION.ASSESSMENT_SECTION_SCORES.API.path}/${sectionScore.id}`, values)
    request
      .then(response => {
        setSaving(false)
        setShowSuccess(true)
      })
      .catch(error => {
      })
  }
  const handleConfirm = () => {
    setShowSuccess(false)
  }

  return (
    <>
      <ConfirmModal
        show={showSuccess}
        title={modalTitle}
        confirmText={t('common.dialogs.actions.okay')}
        handleConfirm={handleConfirm}
      />

      <Form noValidate onSubmit={handleSubmit(onSave)}>
        <Form.Group>
          <Form.Label>
            {t('common.form.section_recommendation')}
          </Form.Label>

          <div
            className={`${errors.recommendations ? 'is-invalid' : ''}`}
          >
            <TinyMceEditor
              value={recommendations}
              onEditorChange={(e) => setValue('recommendations', e)}
              initialValue={sectionScore?.recommendations}
            />
          </div>

          <Form.Control.Feedback type="invalid">
            {errors.recommendations && errors.recommendations.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mt-2">
          <LoadingButton
            loading={saving}
            type="submit"
            className="text-white"
            titleTranslationKey="pages.assessments.buttons.save_recommendation"
          />
        </Form.Group>
      </Form>
    </>
  )
}

export default SectionRecommendationForm