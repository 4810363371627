import Login from '@/views/auth/Login'
import SignUp from '@/views/auth/SignUp'
import LOCATION from '../constants/Location'
import ForgotPassword from '@/views/auth/ForgotPassword'
import ResetPasswordForm from '@/views/auth/ResetPasswordForm'
import FullReport from '../views/pages/Assessments/FullReport'

const Public = [
  {
    path: '/login',
    component: Login,
    public: true,
    authVisit: false,
    exact: true,
  },
  {
    path: '/sign-up',
    component: SignUp,
    public: true,
    authVisit: false,
    exact: true,
  },
  {
    path: LOCATION.AUTH.FORGOT_PASSWORD.path,
    component: ForgotPassword,
    public: true,
    authVisit: false,
    exact: true,
  },
  {
    path: LOCATION.AUTH.RESET_PASSWORD.path,
    component: ResetPasswordForm,
    public: true,
    authVisit: false,
    exact: true,
  },
  {
    path: LOCATION.ASSESSMENTS.FULL_REPORT_SHARED.path,
    component: FullReport,
    exact: true,
    public: true,
    authVisit: true,
  },
]

export default Public
