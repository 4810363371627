import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { useAuth } from '@/services/Auth'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import ConfirmModal from '@/components/modals/ConfirmModal'
import LOCATION from '@/constants/Location'
import LoadingButton from '@/components/LoadingButton'
import LoadingCover from '@/components/LoadingCover'
import CircleCheck from '@/components/icons/CircleCheck'
import CircleXMark from '@/components/icons/CircleXMark'

function PromoteListing () {
  const { t } = useTranslation()
  const auth = useAuth()

  const [companies, setCompanies] = useState(null)
  const [updatingDetails, setUpdatingDetails] = useState(false)
  const [loading, setLoading] = useState(false)
  const [promotionRequests, setPromotionRequests] = useState(null)

  const [showSuccess, setShowSuccess] = useState(false)
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')

  const schema = yup.object().shape({
    message: yup.string()
      .required(t('common.form_validation.is_required', { attribute: t('common.form.message') })),
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const onSave = (values) => {
    setUpdatingDetails(true)
    auth.postRequest(`${LOCATION.PROMOTION_REQUESTS.API.path}`, values)
      .then(response => {
        setPromotionRequests([response.data])
        setShowSuccess(true)
        setUpdatingDetails(false)
        setTitle(t('pages.my_account.notifications.titles.promotion_request_sent'))
        setBody(t('pages.my_account.notifications.body.promotion_request_sent'))

      })
      .catch(error => {
        setUpdatingDetails(false)
      })
  }

  const handleConfirm = () => {
    setShowSuccess(false)
  }

  const getRequests = () => {
    let request = auth.getRequest(`${LOCATION.PROMOTION_REQUESTS.API.path}`)

    request
      .then(response => {
        setPromotionRequests(response.data)

        request =  getBusinessListings()
      })
      .catch(error => {
        setLoading(false)
      })

    return request;
  }

  const getBusinessListings = () => {
    let request = auth.getRequest(`${LOCATION.COMPANIES.API.path}`)

    request
      .then(response => {
        setCompanies(response.data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })

    return request;
  }

  useEffect(() => {
    let request = getRequests();
    return () => request?.abort && request.abort()
  }, [])

  if (loading) {
    return <LoadingCover/>
  }

  if (promotionRequests?.length > 0) {
    return (
      <div className="container request-sent d-flex justify-content-center align-items-center h-100 mt-5">
        <Card className="border-0">
          <Card.Body className="text-center">
            <CircleCheck/>
            <h3 className="mt-4">{t('pages.my_account.notifications.titles.promotion_request_sent')}</h3>
            <p className="my-4">{t('pages.my_account.notifications.body.promotion_request_sent')}</p>
          </Card.Body>
        </Card>
      </div>
    )
  }

  if (companies && !companies?.length > 0) {
    return (
      <div className="container request-sent d-flex justify-content-center align-items-center h-100 mt-5">
        <Card className="border-0">
          <Card.Body className="text-center">
            <CircleXMark/>
            <h3 className="mt-4">{t('pages.my_account.notifications.titles.business_listing_missing')}</h3>
            <p className="my-4">{t('pages.my_account.notifications.body.business_listing_missing')}</p>
            <div className="d-grid gap-2">
              <Button onClick={() => window.location.reload()}>
                {t('pages.my_account.buttons.refresh')}
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
    )
  }

  return (
    <>
      <ConfirmModal
        show={showSuccess}
        title={title}
        body={body}
        confirmText={t('common.dialogs.actions.okay')}
        handleConfirm={handleConfirm}
      />

      <Row className="mt-2">
        <Col className="col-12">
          <h5 className="fw-bold">{t('pages.my_account.promote_listing')}</h5>
          <p className="my-4">{t('pages.my_account.promote_listing_message')}</p>

          <Form noValidate onSubmit={handleSubmit(onSave)} className="row">
            <Form.Group className="col  col-12 mt-3" controlId="formBasicMessage">
              <Form.Label>
                {t('common.form.your_message')}
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.your_message') })}
                {...register('message')}
                isInvalid={!!errors.message}
              />

              <Form.Control.Feedback type="invalid">
                {errors.message && errors.message.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col col-12 mt-4">
              <div className="d-grid gap-2">
                <LoadingButton
                  type="submit"
                  className="text-white mt-4"
                  titleTranslationKey="common.submit"
                  loading={updatingDetails}
                />
              </div>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default PromoteListing