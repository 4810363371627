import {Spinner} from "react-bootstrap";

const LoadingCover = () => {
    return (
        <div className="w-100 d-flex justify-content-center align-items-center" style={{height: '100%'}}>
            <Spinner animation="border" role="status" variant="primary" size="lg">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}

export default LoadingCover;
