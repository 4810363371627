import login from './Login'
import signup from './Signup'
import assessments from './Assessments'
import assessment_audit from './AssessmentAudit'
import my_assessments from './MyAssessments'
import new_assessment from './NewAssessment'
import my_account from './MyAccount'
import users from './Users'
import plans from './Plans'
import forgot_password from './ForgotPassword'
import reset_password from './ResetPassword'
import assessment_templates from './AssessmentTemplates'
import competency_templates from './CompetencyTemplates'
import our_network from './OurNetwork'
import payments from './Payments'

const pages = {
  login,
  signup,
  forgot_password,
  reset_password,
  assessments,
  assessment_templates,
  assessment_audit,
  my_assessments,
  new_assessment,
  my_account,
  users,
  plans,
  competency_templates,
  our_network,
  payments,
}

export default pages