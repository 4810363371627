import { Button, Card, Form } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { useTranslation } from 'react-i18next'
import Question from './components/Question'
import ArrowRight from '@/components/icons/ArrowRight'
import AddressField from './components/AddressField'
import { Link } from 'react-router-dom'
import LOCATION from '@/constants/Location'
import LoadingButton from '@/components/LoadingButton'

function AssessmentSectionForm ({
  sectionIndex,
  section,
  lastSection,
  saveSection,
  firstSlide,
  referenceNumber,
  address,
  auditMode,
  answers,
  submitFormRef,
  responsiblePerson,
  assessment,
  assessmentTemplate,
  responseError,
  validationErrors,
  saveDraft,
  submitting,
}) {

  const { t } = useTranslation()

  const [questions, setQuestions] = useState([])

  const questionSchema = {
    required: yup.string(),
    value: yup.mixed()
      .typeError(t('common.form_validation.is_required', { attribute: t('common.form.this_question') }))
      .when(['required', 'type'], function (required,type, schema) {
        return (parseInt(required) === 1 && !([2,3,6].includes(parseInt(type)))) ?
          schema.required(t('common.form_validation.is_required', { attribute: t('common.form.this_question') }))
          : schema.nullable()
      }),
    explain_other: yup.string().nullable(),
    add_own_rating: yup.string().nullable(),
    other_answer: yup.string()
      .typeError(t('common.form_validation.is_required', { attribute: t('common.form.this_question') }))
      .when(['explain_other'], function (explain_other, schema) {
        return explain_other == 'true' ?
          schema.required(t('common.form_validation.is_required', { attribute: t('common.form.this_question') }))
          : schema.nullable()
      }),
    user_choice_score: yup.string()
      .typeError(t('common.form_validation.is_required', { attribute: t('common.form.this_question') }))
      .when(['explain_other', 'add_own_rating'], function (explain_other, add_own_rating, schema) {
        return explain_other == 'true' && add_own_rating == 'true' ?
          schema.required(t('common.form_validation.is_required', { attribute: t('common.form.this_question') }))
          : schema.nullable()
      }),
  }

  const schema = yup.object().shape({
    address: (sectionIndex === 0 && assessmentTemplate?.assessment_template_type === 1) ? yup.string()
      .typeError(t('common.form_validation.is_required', { attribute: t('common.form.address') }))
      .required(t('common.form_validation.is_required', { attribute: t('common.form.address') })) : yup.string().nullable(),
    responsible_person: (sectionIndex === 0 && assessmentTemplate?.assessment_template_type === 1) ? yup.string().required(t('common.form_validation.is_required', { attribute: t('common.form.responsible_person') })) : yup.string().nullable(),
    questions: yup.array().of(yup.object().shape({
      ...questionSchema,
      children: yup.array().of(yup.array().of(yup.object().shape({
        ...questionSchema
      }))),
    })),
  })

  const {
    resetField,
    handleSubmit,
    register,
    setValue,
    watch,
    unregister,
    formState: { errors },
  } = useForm({
    defaultValues: (_ => {
      return {
        fra_reference_number: referenceNumber,
        address:  assessmentTemplate?.assessment_template_type === 2 ? 'none' : address,
        responsible_person: assessmentTemplate?.assessment_template_type === 2 ? 'none' : responsiblePerson,
      }
    })(),
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (section && section.questions) {
      setQuestions(section.questions)
    }
  }, [section]);

  useEffect(() => {
    if (referenceNumber) {
      setValue('fra_reference_number', referenceNumber)
    }
    if (responsiblePerson) {
      setValue('responsible_person', responsiblePerson)
    }
    if (address) {
      setValue('address', address)
    }
  }, [referenceNumber, address])

  return (
    <Form noValidate onSubmit={handleSubmit(saveSection)} className="">
      <Card className="border-0 radius-0 section-form  mx-auto mx-lg-5">
        <Card.Body className="client">
          {
            firstSlide && assessmentTemplate?.assessment_template_type === 1 &&
            <>
              <Form.Group className="mb-4" controlId="formBasicTitle">
                <Form.Label>
                  {t('common.form.fra_reference_number')}
                </Form.Label>
                <Form.Control
                  disabled={true}
                  {...register('fra_reference_number')}
                  isInvalid={!!errors.fra_reference_number}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.fra_reference_number && errors.fra_reference_number.message}
                </Form.Control.Feedback>
              </Form.Group>


              <Form.Group className="mb-4 mt-4" controlId="formBasicAddress">
                <Form.Label>
                  {t('common.form.where_is_your_property_located')}
                  <span className="text-danger">*</span>
                </Form.Label>
                <AddressField
                  errors={errors}
                  register={register}
                  setValue={setValue}
                  address={address}
                  auditMode={auditMode}
                />
              </Form.Group>
              <Form.Group className="mb-4" controlId="formBasicName">
                <Form.Label>
                  {t('common.form.responsible_person')}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  disabled={auditMode}
                  {...register('responsible_person')}
                  isInvalid={!!errors.responsible_person}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.responsible_person && errors.responsible_person.message}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          }
          {
            questions.map((question, index) =>
              <Question
                setValue={setValue}
                answers={answers}
                auditMode={auditMode}
                watch={watch}
                repeatIndex={null}
                childQuestion={false}
                sectionIndex={sectionIndex}
                index={index}
                key={question.id}
                question={question}
                errors={errors.questions}
                register={register}
                unregister={unregister}
                resetField={resetField}
                inputNameSuffix={`questions[${index}]`}
              />
            )
          }

        </Card.Body>
      </Card>
      {
        responseError &&
        <h6 className="text-danger my-4">{t(responseError)}</h6>
      }
      {
        (validationErrors.length !== 0) &&
        <div className="form-group mb-2">
          {
            validationErrors.map((error, index) =>
              <p className="text-danger font-weight-bold" key={index}>{t(error)}</p>)
          }
        </div>
      }
      <Form.Group className="d-flex justify-content-between mt-3 section-form  mx-auto mx-lg-5">
        {
          auditMode ?
            <Link to={`${LOCATION.ASSESSMENTS_AUDIT.LIST.path}`} className="btn btn-secondary btn-sm">
              <span>{t('pages.new_assessment.save_draft')}</span>
            </Link>
            :
            <Button variant="secondary" size="sm" className="" onClick={() => saveDraft()}>
              <span>{t('pages.new_assessment.save_draft')}</span>
            </Button>
        }
        {
          auditMode ?
            <>
              <Link to={`${LOCATION.ASSESSMENTS.LIST.path}/${assessment?.id}/1/report`}
                    className="btn btn-primary text-white btn-sm">
                <span className="me-1">{t('pages.assessments.buttons.got_to_ratings_report')}</span>
                <ArrowRight/>
              </Link>
              <button type="submit" ref={submitFormRef} className="d-none"></button>
            </> :
            <LoadingButton
              refName={submitFormRef}
              variant="success"
              size="sm"
              type="submit"
              className="text-white"
              loading={submitting}
              titleTranslationKey={ lastSection ? t('pages.assessments.buttons.go_to_ratings_report') : t('pages.assessments.buttons.continue_next_step')}
              icon={<ArrowRight/>}
            />
        }
      </Form.Group>
    </Form>
  )
}

export default AssessmentSectionForm