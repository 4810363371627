import { Card, Col, Form, Row } from 'react-bootstrap'
import { Elements } from '@stripe/react-stripe-js'
import StripeSetupForm from './StripeSetupForm'
import React, { useState } from 'react'
import { useAuth } from '@/services/Auth'
import { useTranslation } from 'react-i18next'
import PaymentMethodInformation from '../components/PaymentMethodInformation'
import LoadingButton from '../../../../components/LoadingButton'

function SubscriptionForm ({
  processSubmit,
  savePaymentMethod,
  stripePromise,
  stripeSetupFormRef,
  errors,
  loading,
  subscribe,
  setSubscriptionDuration,
  subscriptionDuration,
  plan
}) {

  const auth = useAuth()
  const { t } = useTranslation()

  const [paymentMethod, setPaymentMethod] = useState(auth.user.payment_method)

  const submitSubscription = () => {
    subscribe({
      plan_id: plan?.id,
      payment_method_id: paymentMethod?.id,
    })
  }

  return (
    <div className="w-100">
      {
        auth.user.payment_method ?
          <>
            <h6 className="heading-text">{t('pages.plans.your_payment_methods_choose_one')}</h6>
            <PaymentMethodInformation
              user={auth.user}
              setPaymentMethod={setPaymentMethod}
              selectedPaymentMethod={paymentMethod}
            />
            <div className="d-grid gap-2 mt-2">
              <LoadingButton
                disabled={!paymentMethod?.id}
                className="auth-btn text-uppercase fw-bold text-white heading-text"
                loading={loading}
                variant="primary"
                size="lg"
                onSubmit={submitSubscription}
                titleTranslationKey={t('pages.plans.buttons.pay_and_subscribe', {
                  price: (plan.price / 100).toFixed(2),
                  currency: auth.user.currency
                })}
                type="button"
              />
            </div>
          </>
          :
          <Card className="mt-2">
            <Form noValidate onSubmit={processSubmit(savePaymentMethod)}>
              <Card.Body>
                <Card.Title>{t('pages.plans.subscription_form')}</Card.Title>
                <div className="my-3">
                  <Elements stripe={stripePromise}>
                    <StripeSetupForm ref={stripeSetupFormRef} errors={errors}/>
                  </Elements>
                </div>
              </Card.Body>
              <Card.Footer className="d-grid gap-2">
                <LoadingButton
                  className="auth-btn text-uppercase fw-bold text-white heading-text"
                  loading={loading}
                  variant="primary"
                  size="lg"
                  titleTranslationKey={t('pages.plans.buttons.pay_and_subscribe', {
                    price: (plan.price / 100).toFixed(2),
                    currency: auth.user.currency
                  })}
                  type="submit"
                />
              </Card.Footer>
            </Form>
          </Card>
      }
    </div>
  )
}

export default SubscriptionForm