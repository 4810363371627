import { Col, Form, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import XIcon from '@/components/icons/XIcon'

function ChoicesFormBuilder ({
  errors,
  questionIndex,
  choiceIndex,
  register,
  removeChoice,
  setValue,
  answerChoice,
  inputSuffix,
  question,
  watch,
  questionSuffix,
  assessment,
}) {
  const { t } = useTranslation()

  const [showChoices, setShowChoices] = useState(false)

  const [choiceScores, setChoiceScores] = useState([])

  let userOwnScoreLabel = t('pages.assessment_templates.user_input')

  const answer = watch(`${inputSuffix}.answer`)
  const addOther = watch(`${questionSuffix}.add_other`)
  const readyOnly = watch(`${inputSuffix}.read_only`)

  useEffect(() => {
    setValue(`${inputSuffix}.answer`, answerChoice?.answer)
    setValue(`${inputSuffix}.score`, assessment?.assessment_template_type === 2 ? 0 : answerChoice?.score)

  }, [answerChoice])

  useEffect(() => {
    let scores = []
    for (let i = 0; i <= 10; i++) {
      scores.push({ label: i, value: i })
    }
    if (addOther && answer === 'Other') {
      scores.push({ label: userOwnScoreLabel, value: 20 })
    }
    setChoiceScores(scores)
    setShowChoices(true)
  }, [question, answer, addOther])

  if (!showChoices) {
    return null
  }

  return (
    <Row className="my-3 w-100">
      <Col className="col-2 col-md-1 d-flex">
        <div className="counter-space choices py-2 px-2 px-md-3 me-0 me-md-3 d-flex align-items-start">
          {choiceIndex + 1}
        </div>
      </Col>
      <Col className="col-9 col-md-10 d-flex flex-column flex-md-row">
        <input
          type="hidden"
          value={answerChoice?.ready_only ?? 0}
          {...register(`${inputSuffix}.read_only`)}
        />
        <Form.Group className="choice-answer mx-0 mx-md-2 mb-2 mb-md-0">
          <Form.Control
            disabled={readyOnly && readyOnly != '0'}
            placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.answer') })}
            {...register(`${inputSuffix}.answer`)}
            isInvalid={!!(
              errors &&
              errors[choiceIndex] &&
              errors[choiceIndex].answer
            )}
          />

          <Form.Control.Feedback type="invalid">
            {
              errors &&
              errors[choiceIndex] &&
              errors[choiceIndex].answer &&
              errors[choiceIndex].answer.message
            }
          </Form.Control.Feedback>
        </Form.Group>

        {
          assessment?.assessment_template_type === 2 ? null :
            <Form.Group className="choice-score">
              <Form.Select
                type="number"
                aria-label={t('common.form.placeholder_choose', { attribute: t('common.form.score') })}
                placeholder={t('common.form.placeholder_choose', { attribute: t('common.form.score') })}
                {...register(`${inputSuffix}.score`)}
                isInvalid={!!(
                  errors &&
                  errors[choiceIndex] &&
                  errors[choiceIndex].score
                )}
              >
                {
                  choiceScores.map(score => <option value={score.value} key={score.value}>{score.label}</option>)
                }
              </Form.Select>

              <Form.Control.Feedback type="invalid">
                {
                  errors &&
                  errors[choiceIndex] &&
                  errors[choiceIndex].score &&
                  errors[choiceIndex].score.message
                }
              </Form.Control.Feedback>
            </Form.Group>
        }

      </Col>

      <Col className="col-1 p-0 d-flex align-items-center">
        <span
          className="text-danger x-icon mx-3"
          role="button"
          onClick={() => removeChoice(choiceIndex)}
        >
          <XIcon/>
        </span>
      </Col>
    </Row>
  )
}

export default ChoicesFormBuilder