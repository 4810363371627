import { GoogleMap, useLoadScript, Marker, } from '@react-google-maps/api'
import LoadingCover from '@/components/LoadingCover'
import React, { useEffect, useMemo, useState } from 'react'
import { Form, ListGroup } from 'react-bootstrap'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'
import { useTranslation } from 'react-i18next'

const libraries = ['places']

function AddressField ({ errors, register, setValue, auditMode, address }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOODLE_MAPS_API_TOKEN,
    libraries,
  })

  const [selected, setSelected] = useState(null)

  const center = useMemo(() => ({ lat: selected? selected.lat : 51.509865, lng: selected? selected.lng : -0.118092 }), [selected])

  const updateValue = (value) => {
    setValue('address', value)
  }

  useEffect(() => {
    register('address')
  }, [])

  if (!isLoaded) return <LoadingCover/>

  return (
    <>
      <PlacesAutocomplete
        setSelected={setSelected}
        errors={errors}
        register={register}
        address={address}
        auditMode={auditMode}
        updateValue={updateValue}
      />


      <Form.Control.Feedback type="invalid">
        {errors && errors.address && errors.address.message}
      </Form.Control.Feedback>
      <GoogleMap zoom={17} center={center} mapContainerClassName="map-container">
        {selected && <Marker position={selected}/>}
      </GoogleMap>
    </>)
}

const PlacesAutocomplete = ({ setSelected, errors, updateValue, register, address, auditMode }) => {

  const { t } = useTranslation();
  const {
          ready,
          value,
          setValue,
          suggestions: { status, data },
          clearSuggestions,
        } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    setValue(address, false)
    updateValue(address)
    clearSuggestions()

    const results = await getGeocode({ address })
    const { lat, lng } = await getLatLng(results[0])
    setSelected({ lat, lng })
  }

  useEffect(() => {
    handleSelect(address);

  }, [address])

  return (
    <div className="address-combo mb-2">
      <Form.Control
        isInvalid={!!(errors && errors.address)}
        disabled={!ready || auditMode}
        value={value ?? ''}
        onChange={(e) => setValue(e.target.value)}
      />

      <Form.Control.Feedback type="invalid">
        {errors && errors.address && errors.address.message}
      </Form.Control.Feedback>
      {
        (status === 'OK' && !auditMode) &&
        <ListGroup>
          {
            data.map(({ place_id, description }) => (
              <ListGroup.Item action onClick={() =>handleSelect(description) } key={place_id}>{description}</ListGroup.Item>
            ))
          }
        </ListGroup>
      }
    </div>
  )
}

export default AddressField