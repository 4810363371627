function SectionIcon(){
    return (
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38 19C38 29.4934 29.4934 38 19 38C8.50658 38 0 29.4934 0 19C0 8.50658 8.50658 0 19 0C29.4934 0 38 8.50658 38 19ZM3.79999 19C3.79999 27.3947 10.6053 34.2 19 34.2C27.3947 34.2 34.2 27.3947 34.2 19C34.2 10.6053 27.3947 3.79999 19 3.79999C10.6053 3.79999 3.79999 10.6053 3.79999 19Z" fill="#FF5D17" fillOpacity="0.05"/>
            <path d="M17.72 20.2C17.72 20.9 17.96 21.5 18.38 21.96L20.56 21.32C20.36 21.14 20.2 20.8 20.2 20.48C20.2 19.74 20.82 19.3 21.52 18.78C22.46 18.12 23.54 17.32 23.54 15.66C23.54 13.8 21.96 12.5 19.38 12.5C17.66 12.5 16.06 13.2 15 14.44L16.62 16.26C17.24 15.52 18.08 15.02 19.08 15.02C20.18 15.02 20.64 15.54 20.64 16.16C20.64 16.76 20.1 17.14 19.48 17.58C18.68 18.16 17.72 18.82 17.72 20.2ZM17.72 24.64C17.72 25.52 18.46 26.26 19.34 26.26C20.22 26.26 20.96 25.52 20.96 24.64C20.96 23.76 20.22 23.02 19.34 23.02C18.46 23.02 17.72 23.76 17.72 24.64Z" fill="#343A40"/>
            <path d="M36.1007 19C37.1501 19 38.0106 19.8528 37.9058 20.8969C37.7208 22.7413 37.2664 24.5521 36.5544 26.271C35.5996 28.5762 34.2001 30.6707 32.4358 32.435C30.6715 34.1993 28.5769 35.5989 26.2717 36.5537C23.9665 37.5085 21.4959 38 19.0008 38C16.5056 38 14.035 37.5085 11.7298 36.5537C9.42459 35.5989 7.33005 34.1993 5.56574 32.435C3.80143 30.6707 2.4019 28.5762 1.44707 26.271C0.735095 24.5521 0.280761 22.7413 0.0957022 20.8969C-0.00905696 19.8528 0.85144 19 1.90078 19C2.95012 19 3.78854 19.8539 3.91937 20.8951C4.08837 22.24 4.43689 23.5592 4.9578 24.8168C5.72167 26.6609 6.8413 28.3366 8.25274 29.748C9.66419 31.1595 11.3398 32.2791 13.184 33.043C15.0281 33.8068 17.0047 34.2 19.0008 34.2C20.9968 34.2 22.9734 33.8068 24.8175 33.043C26.6617 32.2791 28.3373 31.1595 29.7488 29.748C31.1602 28.3366 32.2798 26.6609 33.0437 24.8168C33.5646 23.5592 33.9131 22.24 34.0821 20.8951C34.213 19.8539 35.0514 19 36.1007 19Z" fill="#FF5D17"/>
            <path d="M2.48267 23.4258C1.46908 23.6974 0.417197 23.0964 0.248155 22.0608C-0.0504569 20.2313 -0.0802925 18.3646 0.162548 16.52C0.488226 14.0463 1.29796 11.6609 2.54552 9.50003C3.79307 7.3392 5.45402 5.44525 7.43353 3.92633C9.41303 2.4074 11.6723 1.29324 14.0824 0.647454C16.4925 0.0016719 19.0062 -0.163084 21.48 0.162594C23.9537 0.488273 26.3391 1.29801 28.5 2.54556C30.6608 3.79312 32.5547 5.45407 34.0737 7.43357C35.2063 8.90959 36.1138 10.5412 36.7699 12.2748C37.1413 13.2562 36.5309 14.3026 35.5173 14.5742C34.5037 14.8458 33.4728 14.238 33.077 13.2662C32.5657 12.0108 31.8876 10.8268 31.0589 9.74686C29.8438 8.16326 28.3286 6.8345 26.6 5.83645C24.8713 4.83841 22.963 4.19062 20.984 3.93008C19.005 3.66954 16.994 3.80134 15.0659 4.31797C13.1379 4.83459 11.3304 5.72592 9.74681 6.94107C8.16321 8.15621 6.83445 9.67136 5.83641 11.4C4.83836 13.1287 4.19058 15.037 3.93003 17.016C3.75236 18.3656 3.75714 19.73 3.942 21.0729C4.0851 22.1124 3.49625 23.1542 2.48267 23.4258Z" fill="#FF5D17"/>
            <path d="M19 1.9C19 0.850658 19.8527 -0.00983937 20.8968 0.0949211C22.7412 0.279981 24.5521 0.734314 26.271 1.44629C28.5761 2.40113 30.6707 3.80065 32.435 5.56497C34.1993 7.32928 35.5988 9.42382 36.5537 11.729C37.5085 14.0342 38 16.5049 38 19C38 21.4951 37.5085 23.9658 36.5537 26.271C35.5988 28.5761 34.1993 30.6707 32.435 32.435C30.6707 34.1993 28.5761 35.5988 26.2709 36.5537C24.5521 37.2656 22.7412 37.72 20.8968 37.905C19.8527 38.0098 19 37.1493 19 36.1C19 35.0506 19.8539 34.2122 20.895 34.0814C22.24 33.9124 23.5592 33.5638 24.8168 33.0429C26.6609 32.2791 28.3365 31.1594 29.748 29.748C31.1594 28.3365 32.2791 26.6609 33.0429 24.8168C33.8068 22.9726 34.2 20.9961 34.2 19C34.2 17.0039 33.8068 15.0273 33.0429 13.1832C32.2791 11.339 31.1594 9.66342 29.748 8.25197C28.3365 6.84052 26.6609 5.7209 24.8168 4.95702C23.5592 4.43611 22.24 4.08759 20.895 3.91859C19.8539 3.78776 19 2.94934 19 1.9Z" fill="#FF5D17"/>
        </svg>
    )
}

export default SectionIcon;