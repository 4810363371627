import { Col, Nav, Row, Tab } from 'react-bootstrap'
import LicenseDetails from './tabs/LicenseDetails'
import OrganizationDetails from './tabs/OrganizationDetails'
import LogoUploads from './tabs/LogoUploads'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import BusinessListing from './tabs/BusinessListing'
import PromoteListing from './tabs/PromoteListing'

function MyAccount () {
  const auth = useAuth()
  const { t } = useTranslation()

  return (
    <div className="content">
      <div className="section-header d-flex justify-content-between mb-4">
        <h4>{t('pages.my_account.title')}</h4>
      </div>
      <Tab.Container defaultActiveKey="licence_details">
        <Row className="my-account-tabs">
          <Col>
            <Nav variant="pills" className="flex-md-row flex-column">
              <Nav.Item className="">
                <Nav.Link eventKey="licence_details">{t('pages.my_account.licence_details')}</Nav.Link>
              </Nav.Item>
              {
                !auth?.user?.branch_user &&
                <Nav.Item className="mx-md-2 mt-md-0 mt-3">
                  <Nav.Link eventKey="organization_details">{t('pages.my_account.organization_details')}</Nav.Link>
                </Nav.Item>
              }
              <Nav.Item className="mx-md-2 mt-md-0 mt-3">
                <Nav.Link eventKey="logo_uploads">{t('pages.my_account.logo_uploads')}</Nav.Link>
              </Nav.Item>
              <Nav.Item className="mx-md-2 mt-md-0 mt-3">
                <Nav.Link eventKey="business_listing">{t('pages.my_account.business_listing')}</Nav.Link>
              </Nav.Item>
              <Nav.Item className="mx-md-2 mt-md-0 mt-3">
                <Nav.Link eventKey="promote_listing">{t('pages.my_account.promote_listing')}</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Tab.Content>
              <Tab.Pane eventKey="licence_details">
                <LicenseDetails/>
              </Tab.Pane>

              {
                !auth?.user?.branch_user &&
                <Tab.Pane eventKey="organization_details">
                  <OrganizationDetails/>
                </Tab.Pane>
              }
              <Tab.Pane eventKey="logo_uploads">
                <LogoUploads/>
              </Tab.Pane>
              <Tab.Pane eventKey="business_listing">
                <BusinessListing/>
              </Tab.Pane>
              <Tab.Pane eventKey="promote_listing">
                <PromoteListing/>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  )
}

export default MyAccount