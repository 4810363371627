const LOCATION = {
  ROOT: { path: '/' },

  AUTH: {
    SIGN_IN: { path: '/login' },
    SIGN_OUT: { path: '/sign-out' },
    SIGN_UP: { path: '/sign-up' },
    INVITED: { path: '/r/:profile' },
    FORGOT_PASSWORD: { path: '/password/forgot' },
    RESET_PASSWORD: { path: '/password/reset/:token' },
  },
  ASSESSMENT_TEMPLATES: {
    LIST: { path: '/assessments-templates' },
    CREATE: { path: '/new-assessment-template' },
    EDIT: { path: '/assessments-templates/:id/edit' },
    API: { path: '/assessment-templates' },
  },
  ASSESSMENTS: {
    LIST: { path: '/assessments' },
    API: { path: '/assessments' },
    CREATE: { path: '/new-assessment' },
    FORM: { path: '/new-assessment/:template_id' },
    EDIT_FORM: { path: '/new-assessment/:template_id/:assessment_id' },
    REPORT: { path: '/assessments/:id/report' },
    REPORT_AUDIT: { path: '/assessments/:id/:audit/report' },
    FULL_REPORT: { path: '/assessments/:assessment_id/full-report' },
    FULL_REPORT_SHARED: { path: '/assessments/:reference_number/:share_code' },
    CERTIFICATE: { path: '/assessments/:assessment_id/certificate' },
    CERTIFICATE_AUDIT: { path: '/assessments/:assessment_id/:audit/certificate' },
    AUDIT_REQUEST: { path: '/assessments/:id/request-audit' },
    AUDIT_REQUEST_PAYMENT: { path: '/assessments/:id/request-audit/payment' },
    AUDIT_REQUEST_SUCCESS: { path: '/assessments/:id/request-audit/success' },
    SEND_AUDIT_REPORT: { path: '/assessments/:id/:audit/send-audit-report' },
  },
  AUDIT_REQUEST: {
    LIST: { path: '/audit-requests' },
    API: { path: '/audit-requests' },
  },
  ASSESSMENT_SECTION: {
    API: { path: '/assessment-template-sections' }
  },
  ASSESSMENT_QUESTIONS: {
    API: { path: '/questions' }
  },
  FIELD_TYPES: {
    API: { path: '/field-types' }
  },
  ASSESSMENT_SECTION_SCORES: {
    API: { path: '/assessment-section-scores' }
  },
  ASSESSMENT_STATUS: {
    API: { path: '/assessment-status' }
  },
  ASSESSMENT_AUDI_STATUS: {
    API: { path: '/assessment-audit-status' }
  },
  ASSESSMENTS_AUDIT: {
    LIST: { path: '/assessment-audit' },
    AUDIT: { path: '/assessment-audit/:template_id/:assessment_id' },
    API: { path: '/audit-requests' },
  },
  COMPETENCY_TEMPLATES: {
    LIST: { path: '/competency-templates' },
    CREATE: { path: '/new-competency-template' },
    EDIT: { path: '/competency-templates/:id/edit' },
    API: { path: '/competency-templates' },
  },
  COMPETENCY_TESTS: {
    LIST: { path: '/competency-tests' },
    CREATE: { path: '/new-competency-test' },
    CERTIFICATE: { path: '/competency-tests/:id/certificate' },
    EDIT: { path: '/competency-tests/:id/edit' },
    API: { path: '/competency-tests' },
  },
  USERS: {
    LIST: { path: '/users' },
    SHOW: { path: '/users/:id' },
    API: { path: '/users' },
  },
  My_ACCOUNT: {
    LIST: { path: '/my-account' }
  },
  OUR_NETWORK: {
    API: { path: '/business-listing' },
    LIST: { path: '/our-network' },
    SHOW: { path: '/our-network/:id' },
  },
  ORGANIZATIONS: {
    API: { path: '/organizations' }
  },
  COMPANIES: {
    API: { path: '/companies' }
  },
  PROMOTION_REQUESTS: {
    API: { path: '/promotion-requests' }
  },
  TOKEN_BUNDLES: {
    API: { path: '/token-bundles' }
  },
  TRANSACTIONS: {
    API: { path: '/transactions' }
  },
  PAYMENT_METHODS: {
    NAME: 'payment-methods',
    API: { path: '/payment-methods' }
  },
  SUBSCRIPTIONS: {
    API: { path: '/subscriptions' }
  },
  PAYMENTS: {
    API: { path: '/payments' },
    PRICING_AND_PLANS: { path: '/pricing-and-plans' },
    PRICING_AND_PLANS_OUT_OF_TOKENS: { path: '/pricing-and-plans/:out_of_tokens' },
    TOKEN_CHECKOUT_LINK: { path: '/buy-tokens' },
    TOKEN_CHECKOUT: { path: '/buy-tokens/:token_bundle_id' },
    TOKEN_CHECKOUT_SUCCESS: { path: '/buy-tokens/:token_bundle_id/:number_of_tokens/success' },

    SUBSCRIBE: { path: '/subscribe' },
    SUBSCRIBE_PROCESSING: { path: '/subscribe/processing-payment' },
  },
  PLANS: {
    API: { path: '/plans' },
    SUBSCRIBE: { path: '/plans/:id/subscribe' },
    BUY_CREDIT: { path: '/plans/:id/buy-credit' },
  },
}

export default LOCATION