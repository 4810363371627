import { Alert, Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useRef, useState } from 'react'
import ChoicesFormBuilder from './ChoicesFormBuilder'
import Plus from '@/components/icons/Plus'
import TinyMceEditor from '@/components/TinyMceEditor'
import RangeOptions from './RangeOptions'
import ImageUploadField from '../../Assessments/Forms/components/ImageUploadField'

function QuestionFormBuilder ({
  question,
  errors,
  index,
  register,
  removeQuestion,
  fieldTypes,
  setValue,
  watch,
  questionFields,
  inputSuffix,
  unregister,
  isChild,
  assessment,
  dragStarted,
}) {
  const { t } = useTranslation()

  const editorRef = useRef(null)

  const [answerChoices, setAnswerChoices] = useState([])
  const [choicesRegistered, setChoiceRegistered] = useState(false)
  const [childrenRegistered, setChildrenRegistered] = useState(false)
  const [children, setChildren] = useState([])

  const removeChoice = (choiceIndex) => {
    unregister(`${inputSuffix}.question_choices[${choiceIndex}]`)
    let choices = answerChoices.filter((_, index) => index !== choiceIndex)
    setAnswerChoices([].concat(choices))
  }

  const addChoice = () => {
    answerChoices.push({
      id: null,
      answer: '',
      score: null,
      readOnly: false,
    })
    setAnswerChoices([].concat(answerChoices))
  }

  const removeChildQuestion = (childIndex) => {
    unregister(`${inputSuffix}.children[${childIndex}]`)
    let childrenList = children.filter((_, index) => index !== childIndex)
    setChildren([].concat(childrenList))
  }

  const addChildQuestion = () => {
    children.push(questionFields)

    setChildren([].concat(children))
  }

  useEffect(() => {
    setValue(`${inputSuffix}.type`, `${question.type}`)
    setValue(`${inputSuffix}.label`, question.label)
    setValue(`${inputSuffix}.required`, question.required)
    setValue(`${inputSuffix}.has_help_text`, question.has_help_text)
    setValue(`${inputSuffix}.add_other`, question.add_other)
    setValue(`${inputSuffix}.help_text`, question.help_text)
    setValue(`${inputSuffix}.description`, question.description)
  }, [question])

  const type = parseInt(watch(`${inputSuffix}.type`))
  const addOther = watch(`${inputSuffix}.add_other`)
  const hasHelpText = watch(`${inputSuffix}.has_help_text`)
  const hasScore = watch(`${inputSuffix}.has_score`)
  const helpText = watch(`${inputSuffix}.help_text`)
  const questionScore = watch(`${inputSuffix}.score`)
  const fieldTypeValue = watch(`${inputSuffix}.type`)

  useEffect(() => {
    register(`${inputSuffix}.help_text`)
  }, [])

  useEffect(() => {
    if ([3, 6].includes(type)) {
      setChildrenRegistered(true)
      if (question.children && question.children.length !== 0) {
        setChildren([].concat(question.children))
      } else {
        addChildQuestion()
      }
    } else if (childrenRegistered) {
      unregister(`${inputSuffix}.children`)
      setChildren([].concat([]))
    }
  }, [type, question])

  useEffect(() => {
    if (addOther) {
      answerChoices.push({
        id: null,
        answer: 'Other',
        score: null,
        ready_only: true,
      })
      setAnswerChoices([].concat(answerChoices))
    } else {
      setAnswerChoices([].concat(answerChoices.filter(_ => _.answer !== 'Other')))
    }
  }, [addOther])

  useEffect(() => {
    if ([8, 9, 12, 13].includes(type)) {
      setChoiceRegistered(true)
      if (question?.question_choices?.length !== 0) {
        setAnswerChoices([].concat(question.question_choices))
      } else {
        addChoice()
      }
    } else if (choicesRegistered) {
      unregister(`${inputSuffix}.question_choices`)
    }
  }, [type, question])

  useEffect(() => {
    if (questionScore) {
      register(`${inputSuffix}.score`)
    } else {
      unregister(`${inputSuffix}.score`)
    }
  }, [hasScore])

  return (
    <div className="mb-3 d-flex question-container">
      <div className="counter-space d-flex align-items-start pt-3 py-2 px-3 justify-content-center">{index + 1}</div>

      {
        dragStarted && !isChild &&
          <Form.Control
            className="m-2 w-75 border-0"
            readOnly
            placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.field_label') })}
            {...register(`${inputSuffix}.label`)}
            isInvalid={!!(errors && errors[index] && errors[index].label)}
          />
      }

      {
        !dragStarted &&
        <>
          <input
            type="hidden"
            name="order"
            value={question?.order ?? (index + 1)}
            {...register(`${inputSuffix}.order`)}
          />
          <Row className="p-3 d-flex flex-fill">
            <Form.Group className="my-2">
              <Form.Label>
                {t('common.form.field_type')}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                type="text"
                aria-label={t('common.form.field_type')}
                {...register(`${inputSuffix}.type`)}
                isInvalid={!!(errors && errors[index] && errors[index].type)}
              >
                {
                  fieldTypes.map(fieldType => {
                      if (fieldTypeValue == fieldType.id) {
                        return (
                          <option
                            value={`${fieldType.id}`}
                            key={fieldType.id}
                            selected
                          >
                            {fieldType.name}
                          </option>
                        )
                      } else {
                        return <option
                          value={`${fieldType.id}`}
                          key={fieldType.id}
                        >
                          {fieldType.name}
                        </option>
                      }
                    }
                  )
                }
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors && errors[index] && errors[index].type && errors[index].type.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="my-2">
              <Form.Label>
                {t('common.form.field_label')}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.field_label') })}
                {...register(`${inputSuffix}.label`)}
                isInvalid={!!(errors && errors[index] && errors[index].label)}
              />

              <Form.Control.Feedback type="invalid">
                {errors && errors[index] && errors[index].label && errors[index].label.message}
              </Form.Control.Feedback>
            </Form.Group>
            {
              [10].includes(parseInt(type)) ?
                <Form.Group className="my-2">
                  <Form.Label>
                    {t('common.form.field_description')}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.field_description') })}
                    {...register(`${inputSuffix}.description`)}
                    isInvalid={!!(errors && errors[index] && errors[index].description)}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors && errors[index] && errors[index].label && errors[index].description.message}
                  </Form.Control.Feedback>
                </Form.Group> : null
            }
            {
              [11].includes(parseInt(type)) ?
                <Form.Group className="my-2">
                  <Form.Label>
                    {t('common.form.range_suffix')}
                  </Form.Label>
                  <Form.Control
                    placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.range_suffix') })}
                    {...register(`${inputSuffix}.description`)}
                    isInvalid={!!(errors && errors[index] && errors[index].description)}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors && errors[index] && errors[index].label && errors[index].description.message}
                  </Form.Control.Feedback>
                </Form.Group> : null
            }
            {
              [12].includes(parseInt(type)) ?
                <Form.Group className="my-2">
                  <Form.Label>
                    {t('common.form.range_title')}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.range_title') })}
                    {...register(`${inputSuffix}.description`)}
                    isInvalid={!!(errors && errors[index] && errors[index].description)}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors && errors[index] && errors[index].label && errors[index].description.message}
                  </Form.Control.Feedback>
                </Form.Group> : null
            }
            {
              [6].includes(parseInt(type)) ?
                <Form.Group className="my-2">
                  <Form.Label>
                    {t('common.form.max_total_input_value')}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.max_total_input_value') })}
                    {...register(`${inputSuffix}.max_total_input_value`)}
                    isInvalid={!!(errors && errors[index] && errors[index].max_total_input_value)}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors && errors[index] && errors[index].label && errors[index].max_total_input_value.message}
                  </Form.Control.Feedback>
                </Form.Group> : null
            }
            {
              [11].includes(parseInt(type)) ?
                <RangeOptions
                  register={register}
                  inputSuffix={inputSuffix}
                  errors={errors}
                  index={index}
                /> : null
            }
            {
              [3, 6].includes(parseInt(type)) ?
                <Form.Group>
                  {
                    children.map((childQuestion, childIndex) =>
                      <QuestionFormBuilder
                        key={childIndex}
                        errors={errors && errors[index] && errors[index].children}
                        setValue={setValue}
                        register={register}
                        question={childQuestion}
                        index={childIndex}
                        unregister={unregister}
                        removeQuestion={removeChildQuestion}
                        fieldTypes={fieldTypes.filter(_ => _.id !== 3 && _.id !== 6)}
                        watch={watch}
                        isChild={true}
                        inputSuffix={`${inputSuffix}.children[${childIndex}]`}
                      />
                    )
                  }
                  <div className="d-grid gap-2">
                    <Button
                      size="sm"
                      variant="success"
                      onClick={() => addChildQuestion()}
                    >
                      <span className="">{t('pages.assessments.buttons.add_field_type')}</span>
                      <Plus/>
                    </Button>
                  </div>
                </Form.Group> : null
            }
            {
              [8, 9, 12, 13].includes(type) ?
                <div className="mt-2">
                  <Row className="my-3">
                    <Col className="col-7">{t('common.choices')}</Col>
                    {
                      assessment?.assessment_template_type === 2 ?
                        null
                        :
                        <Col className="col-5">{t('common.scores')}</Col>
                    }
                  </Row>
                  {
                    answerChoices.map((answerChoice, choiceIndex) =>
                      <ChoicesFormBuilder
                        key={choiceIndex}
                        errors={errors && errors[index] && errors[index].question_choices}
                        questionIndex={index}
                        choiceIndex={choiceIndex}
                        register={register}
                        removeChoice={removeChoice}
                        setValue={setValue}
                        answerChoice={answerChoice}
                        question={question}
                        watch={watch}
                        questionSuffix={inputSuffix}
                        assessment={assessment}
                        inputSuffix={`${inputSuffix}.question_choices[${choiceIndex}]`}
                      />
                    )
                  }

                  <div className="d-grid gap-2">
                    <Button
                      size="sm"
                      variant="success"
                      onClick={() => addChoice()}
                    >
                      <span className="">{t('pages.assessments.buttons.add_choice')}</span>
                      <Plus/>
                    </Button>
                  </div>
                  {
                    [12].includes(type) &&
                    <Row>
                      <RangeOptions
                        register={register}
                        inputSuffix={inputSuffix}
                        errors={errors}
                        index={index}
                      />
                    </Row>
                  }
                  {
                    [13].includes(type) &&
                    <Row>
                      <Form.Group className="my-2 col col-12 my-3">
                        <ImageUploadField
                          watch={watch}
                          errors={errors}
                          questionIndex={index}
                          question={question}
                          setValue={setValue}
                          inputNameSuffix={inputSuffix}
                          register={register}
                          fieldName="image"
                          assessment={assessment}
                          image={question?.image}
                        />
                      </Form.Group>
                    </Row>
                  }
                </div> : null
            }

            {
              assessment?.assessment_template_type === 2 ?
                <Form.Group className="my-2">
                  <Form.Label>
                    {t('common.form.correct_answer')}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.correct_answer') })}
                    {...register(`${inputSuffix}.description`)}
                    isInvalid={!!(errors && errors[index] && errors[index].description)}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors && errors[index] && errors[index].label && errors[index].description.message}
                  </Form.Control.Feedback>
                </Form.Group> : null
            }
            {
              hasScore ?
                <Form.Group className="my-2">
                  <Form.Label>
                    {t('common.form.question_score')}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    min={0}
                    max={10}
                    placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.question_score') })}
                    {...register(`${inputSuffix}.score`)}
                    isInvalid={!!(errors && errors[index] && errors[index].question_score)}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors && errors[index] && errors[index].label && errors[index].question_score.message}
                  </Form.Control.Feedback>
                </Form.Group> : null
            }

            {
              hasHelpText &&
              <Form.Group className="my-2">
                <Form.Label>
                  {t('common.form.help_text')}
                  <span className="text-danger">*</span>
                </Form.Label>
                <div
                  className={`${errors && errors[index] && errors[index].help_text ? 'is-invalid' : ''}`}
                >
                  <TinyMceEditor
                    value={helpText}
                    onEditorChange={(e) => setValue(`${inputSuffix}.help_text`, e)}
                    initialValue={question.help_text}
                  />
                </div>
                <Form.Control.Feedback className="d-block" type="invalid">
                  {errors && errors[index] && errors[index].help_text && errors[index].help_text.message}
                </Form.Control.Feedback>
              </Form.Group>
            }
            <Form.Group className="my-2 d-flex justify-content-between align-items-end">
              <div className="d-flex justify-content-start flex-column flex-xl-row">
                <Form.Check
                  type="checkbox"
                  label={t('common.form.required')}
                  {...register(`${inputSuffix}.required`)}
                />
                {
                  [8, 9, 6].includes(type) && assessment?.assessment_template_type !== 2 ?
                    <Form.Check
                      className="ms-0 ms-xl-4"
                      type="checkbox"
                      label={t('common.form.add_other')}
                      {...register(`${inputSuffix}.add_other`)}
                    />
                    : null
                }
                {
                  assessment?.assessment_template_type !== 2 ?
                    <Form.Check
                      className="ms-0 ms-xl-4"
                      type="checkbox"
                      label={t('common.form.help_text')}
                      {...register(`${inputSuffix}.has_help_text`)}
                    />
                    : null
                }
                {
                  ![8, 9, 12].includes(type) && assessment?.assessment_template_type !== 2 ?
                    <Form.Check
                      className="ms-0 ms-xl-4"
                      type="checkbox"
                      label={t('common.form.has_score')}
                      {...register(`${inputSuffix}.has_score`)}
                    />
                    : null
                }
                {
                  [8].includes(type) ?
                    <Form.Check
                      className="ms-0 ms-xl-4"
                      type="checkbox"
                      label={t('common.form.select_multiple')}
                      {...register(`${inputSuffix}.select_multiple`)}
                    />
                    : null
                }
                {
                  isChild && assessment?.assessment_template_type !== 2 ?
                    <Form.Check
                      className="ms-0 ms-xl-4"
                      type="checkbox"
                      label={t('common.form.is_other')}
                      {...register(`${inputSuffix}.is_other`)}
                    />
                    : null
                }
              </div>
              <Button
                variant="link"
                onClick={() => removeQuestion(index)}
                className="text-danger"
              >
                <span className="text-decoration-underline">{t('pages.assessments.buttons.remove_question')}</span>
              </Button>
            </Form.Group>
          </Row>
        </>
      }
    </div>

  )
}

export default QuestionFormBuilder