import Dashboard from '@/views/Dashboard'
import LOCATION from '@/constants/Location'

import AssessmentTemplateRoutes from './pages/AssessmentTemplateRoutes'
import AssessmentRoutes from './pages/AssessmentRoutes'
import Account from './pages/Account'
import AssessmentAudit from './pages/AssessmentAudit'
import Users from './pages/Users'
import Payments from './pages/Payments'
import CompetencyTemplates from './pages/CompetencyTemplates'
import CompetencyTests from './pages/CompetencyTests'
import OurNetwork from './pages/OurNetwork'

const Private = [
  {
    path: LOCATION.ROOT,
    component: Dashboard,
    exact: true,
    translation_key: 'common.dashboard',
    main: false,
  },
  ...AssessmentTemplateRoutes,
  ...AssessmentRoutes,
  ...Account,
  ...AssessmentAudit,
  ...Users,
  ...Payments,
  ...CompetencyTemplates,
  ...CompetencyTests,
  ...OurNetwork,
]
export default Private
