import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'
import LoadingCover from '@/components/LoadingCover'
import AssessmentFilters from '@/views/components/AssessmentFilters'
import Table, { useColumns } from '@/views/components/Tables/Assessments'
import { Card, Col, Row } from 'react-bootstrap'
import UpdateTokens from './Forms/UpdateTokens'
import moment from 'moment'
import CancelSubscription from './components/CancelSubscription'
import PromotionButton from './components/PromotionButton'

function Show () {
  const history = useHistory()
  const route = useLocation()
  const auth = useAuth()
  const { t } = useTranslation()

  const [assessmentTemplates, setAssessmentTemplates] = useState([])
  const [assessmentStatus, setAssessmentStatus] = useState([])
  const [loading, setLoading] = useState(true)
  const [subscription, setSubscription] = useState(null)

  const { id } = useParams()

  const [user, setUser] = useState(null)

  const [query, setQuery] = useState(route.state && route.state.query || { page: 0, skip: 0, limit: 15 })

  const [searching, setSearching] = useState(false)

  const getSubscription = () => {
    let request = auth.getRequest(`${LOCATION.SUBSCRIPTIONS.API.path}/${id}`)

    request
      .then(response => {
        setSubscription(response.data)
      })
      .catch(error => {
      })

    return request
  }



  useEffect(() => {
    let request = getSubscription()
  }, [])


  useEffect(() => {
    let request = auth.getRequest(LOCATION.ASSESSMENT_TEMPLATES.API.path)

    request
      .then(response => {
        setAssessmentTemplates(response.data.data)
      })
      .catch(error => {
      })

    return () => request?.abort && request.abort()

  }, [])

  useEffect(() => {
    let request = auth.getRequest(`${LOCATION.ASSESSMENT_STATUS.API.path}`)

    request
      .then(response => {
        setAssessmentStatus(response.data.assessment_status)
      })
      .catch(error => {
      })

    return () => request?.abort && request.abort()

  }, [])

  useEffect(() => {
    let request = auth.getRequest(`${LOCATION.USERS.API.path}/${id}`)

    request
      .then(response => {
        setUser(response.data)
        setLoading(false)
        let searchQuery = { ...query }
        searchQuery.user_id = response.data.id
        setQuery(searchQuery)
      })
      .catch(error => {
      })

    return () => request?.abort && request.abort()

  }, [])

  useEffect(() => {
    history.replace(
      route.pathname,
      {
        ...route.state,
        query,
      }
    )
  }, [query])

  if (loading) return <LoadingCover/>

  return (

    <>
      <Row className="h-100">
        <Col className="col-md-8 col-12 p-4">
          <div className="section-header d-flex justify-content-between mb-4">
            <h4>{user.name}</h4>
          </div>
          <h6 className="my-3 fw-bold">{t('pages.assessments.title')}</h6>
          <AssessmentFilters
            hideSearch={true}
            searching={searching}
            values={query}
            loadAssessments={setQuery}
            assessmentStatus={assessmentStatus}
            assessmentTemplates={assessmentTemplates}
          />
          <div className="my-4 bg-white">
            <Table query={query} showPagination={true} onData={_ => setSearching(!_)} source="users"/>
          </div>
        </Col>
        <Col className="col-md-4 col-12 p-0">
          <div className="user-info p-4 m-4 m-md-0 h-100">
            <h5>{t('pages.my_account.licence_details')}</h5>
            <div className="d-flex my-1">
              <span>{t('common.form.email')}</span>
              <span>&nbsp;-&nbsp;{user.email}</span>
            </div>
            <div className="d-flex my-1">
              <span>{t('common.form.phone_number')}</span>
              <span>&nbsp;-&nbsp;{user.phone_number}</span>
            </div>
            <div className="d-flex my-1">
              <span>{t('common.form.serial_number')}</span>
              <span>&nbsp;-&nbsp;{user.serial_number}</span>
            </div>

            <h5 className="mt-4">{t('pages.my_account.organization_details')}</h5>
            <div>{user.organization?.name}</div>
            <div>{user.organization_branch?.name}</div>

            <PromotionButton user={user}/>

            <h5 className="mt-4">{t('pages.users.update_token')}</h5>
            <UpdateTokens user={user}/>

            <h5 className="mt-4">{t('pages.my_account.subscription_details')}</h5>
            {
              subscription ?
                <>
                  <div className="d-flex my-1">
                    <span>{t('pages.plans.subscriptions.subscriber_since')}</span>
                    <span>&nbsp;-&nbsp;{moment(subscription.created_at).format(t('common.formats.short_date'))}</span>
                  </div>
                  <div className="d-flex my-1">
                    <span>{t('pages.plans.subscriptions.expiry_date')}</span>
                    <span>&nbsp;-&nbsp;{moment(subscription.expire_at).format(t('common.formats.short_date'))}</span>
                  </div>
                  <div className="d-flex my-1">
                    <span>{t('pages.plans.subscriptions.renew_type')}</span>
                    <span>&nbsp;-&nbsp;{t('pages.plans.subscriptions.renew_date_monthly')}</span>
                  </div>
                  <div className="w-100 my-1 ">
                    <div className="d-grid gap-2 mt-2">
                      <CancelSubscription id={id} getSubscription={getSubscription}/>
                    </div>
                  </div>
                </>
                :

                <h6 className="my-3 text-danger">{t('pages.plans.subscriptions.no_active_subscription')}</h6>

            }
          </div>
        </Col>
      </Row>
    </>

  )
}

export default Show