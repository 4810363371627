import { Card, Col, Row } from 'react-bootstrap'
import moment from 'moment/moment'
import React from 'react'
import AssessmentScoreIcon from '../AssessmentScoreIcon'
import { getScoreColor } from '@/Utility'
import Logo from '@/components/Logo'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import { getScoreColorValue, getSectionScore } from '@/Utility'

function AssessmentCertificate ({ assessment, small, reportTemplateRef }) {

  const auth = useAuth()
  const baseUrl = auth.api.defaults.baseURL

  let assessorSignature = auth.user.user_signature ? `${baseUrl}/${auth.user.user_signature}` : '/assets/images/signature.png'
  let accreditationLogo = auth.user.organization?.accreditation_logo ? `${baseUrl}/${auth.user.organization.accreditation_logo}` : '/assets/images/logo-placeholder.png'

  const { t } = useTranslation()
  return (
    <div className="container certificate-holder p-1 my-5" ref={reportTemplateRef}>
      <Card className="border-0 shadow assessment-certificate" id="assessment-certificate">
        <Card.Body className="p-4 certificate-card d-flex flex-column">
          <div className="d-flex justify-content-between align-items-start mb-3">
            <div className="certificate-information">
              <h4 className="fw-bold mb-4 text-primary">{t('pages.assessments.report.report_certificate')}</h4>
              <p>{t('pages.assessments.report.fra_assessment_has_been_carried_out_on', { date: moment(assessment.created_at).format(t('common.formats.short_date')) })}</p>
              <p>{t('pages.assessments.report.responsible_person')} - {assessment.responsible_person}</p>
              <p>{t('pages.assessments.report.assessor')} - {assessment.user.name}</p>
              <p>{t('common.form.address')} - {assessment.address}</p>
            </div>
            <img className="certificate-logo" src={accreditationLogo}/>

          </div>
          <div className="d-flex justify-content-between pt-3">
            <div>
              <h4 className="fw-bold">
                {t('pages.assessments.report.rated')}
                <span className={getScoreColor(assessment.score)}>{assessment.score}/10</span>
              </h4>
              <p className="rating-improvement-statement mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing
                elit</p>
            </div>
            <AssessmentScoreIcon
              color={getScoreColorValue(getSectionScore(assessment, false))}
              score={getSectionScore(assessment, false)}
              size={140}
              value={100}
            />
          </div>

          <h5 className="mb-2 pt-2">{t('pages.assessments.report.individual_ratings')}</h5>
          <div className="certificate-scores">
            <Row className="p-3">
              {
                assessment.section_scores.map((sectionScore, index) =>
                  <Col
                    className={`${small ? 'col-xxl-4' : 'col-md-4'} col-6 bg-white px-1`}
                    key={index}
                  >
                    <Card className="border-0 my-2">
                      <Card.Body className="d-flex justify-content-between align-items-center pt-0 px-1">
                        <div>
                          <p className="section-score-title mb-3">{sectionScore.assessment_template_section.name}</p>
                          <p className={`fw-bold section-score text-${sectionScore.score_comment}`}>
                            {t(`pages.assessments.report.${sectionScore.score_comment}`)}
                          </p>
                        </div>

                        <AssessmentScoreIcon
                          color={getScoreColorValue((sectionScore.score))}
                          score={sectionScore.score}
                          size={100}
                          hollowSize="40%"
                          value={100}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                )
              }
            </Row>
          </div>
          <div className="py-4 assessor-signature d-flex flex-row gap-5">
            <div>
              <p className="mb-1">{t('pages.assessments.report.assessor_signature')}</p>
              <img className="certificate-signature" src={assessorSignature}/>
            </div>
            <div className="ms-5">
              <p className="mb-1">{t('pages.assessments.report.fra_signature')}</p>
              <img className="certificate-signature" src="/assets/images/signature.png"/>
            </div>
          </div>
          <div className="mt-auto certificate-footer-section d-flex justify-content-between align-items-center">
            <Logo/>
            <p className="fra-link mb-0">frawizard.co.uk</p>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default AssessmentCertificate