import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'
import LoadingCover from '@/components/LoadingCover'
import { Accordion, Button, Card, Col, Form, Row } from 'react-bootstrap'
import { getScoreColor } from '@/Utility'
import AssessmentScoreIcon from '@/views/components/AssessmentScoreIcon'
import PageActionSection from '@/components/PageActionSection'
import BackButton from '@/views/components/Buttons/BackButton'
import EyeFill from '@/components/icons/EyeFill'
import SectionRecommendationForm from './Forms/SectionRecommendationForm'
import ArrowLeft from '@/components/icons/ArrowLeft'
import { getScoreColorValue, getSectionScore,  getScoreColorCompetency, getScoreColorValueCompetency } from '@/Utility'

function Report () {

  const { t } = useTranslation()
  const auth = useAuth()
  let { id, audit } = useParams()

  const [loading, setLoading] = useState(true)
  const [assessment, setAssessment] = useState(null)
  const [assessmentAudited, setAssessmentAudited] = useState(false)

  useEffect(() => {
    let request = auth.getRequest(`${LOCATION.ASSESSMENTS.API.path}/${id}`)
    request
      .then(response => {
        setAssessment(response.data)
        let sectionScores = response.data.section_scores
        sectionScores.forEach(sectionScore => {
          if (sectionScore.audited) {
            setAssessmentAudited(true)
          }
        })

        setLoading(false)
      })
      .catch(error => {
      })

    return () => request?.abort && request.abort()

  }, [])

  if (loading || !assessment) {
    return (
      <LoadingCover/>
    )
  }

  return (
    <>
      <PageActionSection
        prepend={[<BackButton fillColor='white'/>]}
        append={
          audit ?
            [
              <Link
                to={`${LOCATION.ASSESSMENTS.LIST.path}/${assessment.id}/1/certificate`}
                className="btn btn-light btn-sm text-primary"
              >
                <span className="me-1">{t('pages.assessments.buttons.continue_view_certificate')}</span>
                <EyeFill/>
              </Link>,
            ] :

            assessment?.assessment_template?.assessment_template_type === 2 ?
              [
                assessment?.competency_passed &&
                <Link
                  to={`${LOCATION.COMPETENCY_TESTS.LIST.path}/${assessment?.id}/certificate`}
                  className="btn btn-light btn-sm text-primary"
                >
                  <span className="me-1">{t('pages.competency_templates.buttons.competency_certificate')}</span>
                </Link>,
              ] :
              [
                <Link
                  to={`${LOCATION.ASSESSMENTS.CREATE.path}/${assessment.assessment_template_id}/${assessment.id}`}
                  className="btn btn-light btn-sm text-primary"
                >
                  <span className="">{t('pages.assessments.buttons.edit_assessment')}</span>
                </Link>,
                (auth.user.client && !assessment.audit_requested) ?
                  <Link
                    to={`${LOCATION.ASSESSMENTS.LIST.path}/${assessment.id}/request-audit`}
                    className="text-primary btn btn-light btn-sm"
                  >
                    {t('pages.assessments.buttons.request_audit_for_this_assessment')}
                  </Link> : null,

                <Link
                  to={`${LOCATION.ASSESSMENTS.LIST.path}/${assessment.id}/full-report`}
                  className="btn btn-light btn-sm text-primary"
                >
                  <span className="me-1">{t('pages.assessments.buttons.view_full_report')}</span>
                  <EyeFill/>
                </Link>,

                <Link
                  to={`${LOCATION.ASSESSMENTS.LIST.path}/${assessment.id}/certificate`}
                  className="btn btn-light btn-sm text-primary"
                >
                  <span className="me-1">{t('pages.assessments.buttons.view_certificate')}</span>
                  <EyeFill/>
                </Link>
              ]}
        title={audit ? t('pages.assessments.report.audit_rating_report') : assessment?.assessment_template?.assessment_template_type === 2 ? t('pages.competency_templates.report.title') : t('pages.assessments.report.title')}
        type="primary"
      />

      <div className="container report-container" style={{ maxWidth: '850px' }}>
        <Card className="border-0 my-4">
          <Card.Body className="d-flex justify-content-between align-items-center">
            <div>
              <h2 className="fw-bold">
                {
                  assessmentAudited && audit ?
                    <span>
                      {t('pages.assessments.report.their_updated_rating_is')}
                    </span>
                    :
                    <span>
                    {t('pages.assessments.report.your_rating_is')}
                  </span>
                }
                {
                  assessment?.assessment_template?.assessment_template_type === 2 ?
                    <span
                      className={getScoreColorCompetency((assessment.score / assessment.out_of) * 10)}>{assessment.score}/{assessment.out_of}</span>
                    :
                    <span className={getScoreColor(assessment.score)}>{assessment.score}/10</span>
                }
              </h2>
              {
                assessment?.assessment_template?.assessment_template_type === 2 ?
                  <>

                    <p className="rating-improvement-statement mt-3">
                      {t('pages.competency_templates.you_need_to_pass')}
                    </p>
                    {
                      !assessment?.competency_passed ?
                        <Link
                          style={{ width: '150px' }}
                          className="btn btn-primary btn-sm mt-3"
                          to={`${LOCATION.ASSESSMENTS.CREATE.path}/${assessment?.assessment_template_id}`}
                        >
                          <ArrowLeft/>
                          <span className="ms-1">{t('pages.competency_templates.buttons.restart_test')}</span>
                        </Link>
                        :
                        <Link
                          className="btn btn-primary btn-sm mt-3"
                          to={`${LOCATION.COMPETENCY_TESTS.LIST.path}/${assessment?.id}/certificate`}
                        >
                          <span className="ms-1">{t('pages.competency_templates.buttons.competency_certificate')}</span>
                        </Link>
                    }
                  </>
                  :
                  assessmentAudited && audit ?
                    <p className="rating-improvement-statement mt-3">
                      {t('pages.assessments.report.show_how_it_can_be_improved_below')}
                    </p>
                    :
                    <p className="rating-improvement-statement mt-3">
                      {t('pages.assessments.report.see_how_it_can_be_improved_below')}
                    </p>
              }
            </div>
            {
              assessment?.assessment_template?.assessment_template_type === 1 ?

                <AssessmentScoreIcon
                  color={getScoreColorValue(getSectionScore(assessment, false))}
                  score={getSectionScore(assessment, false)}
                  size={138}
                  value={100}
                />
                :
                <AssessmentScoreIcon
                  color={getScoreColorValueCompetency(getSectionScore(assessment, false) * 10)}
                  score={getSectionScore(assessment, true)}
                  size={138}
                  suffix="%"
                  value={getSectionScore(assessment, true)}
                />
            }
          </Card.Body>
        </Card>
        <h5 className="mb-2 mt-4 pt-4 fw-bold">{t('pages.assessments.report.individual_ratings')}</h5>
        <Row>
          {
            assessment.section_scores.map(sectionScore =>
              <Col className="col-md-4 col-sm-6 col-12" key={sectionScore.id}>
                <Card className="border-0 my-2">
                  <Card.Body className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="section-score-title mb-4">{sectionScore.assessment_template_section.name}</p>
                      {
                        assessment?.assessment_template?.assessment_template_type === 1 ?
                          <p
                            className={`fw-bold section-score text-${sectionScore.score_comment}`}>{t(`pages.assessments.report.${sectionScore.score_comment}`)}</p>
                          :
                          <p
                            className={`fw-bold section-score text-${sectionScore.score_comment}-competency`}>{t(`pages.assessments.report.${sectionScore.score_comment}`)}</p>
                      }
                    </div>
                    {
                      assessment?.assessment_template?.assessment_template_type === 1 ?
                        <AssessmentScoreIcon
                          color={getScoreColorValue((sectionScore.score))}
                          score={sectionScore.score}
                          size={100}
                          hollowSize="40%"
                          value={100}
                        />
                        :
                        <AssessmentScoreIcon
                          color={getScoreColorValueCompetency((sectionScore.score / sectionScore.out_of) * 10)}
                          score={((sectionScore.score / sectionScore.out_of) * 100).toFixed(0)}
                          size={100}
                          suffix="%"
                          value={((sectionScore.score / sectionScore.out_of) * 100).toFixed(0)}
                        />
                    }
                  </Card.Body>
                </Card>
              </Col>
            )
          }
        </Row>

        {
          assessment?.assessment_template?.assessment_template_type === 1 ?
            <>
              <h5
                className="mb-2 mt-4 pt-4 fw-bold">{t('pages.assessments.report.recommendations_for_improvement')}</h5>
              <Row>
                <Accordion defaultActiveKey={0} flush>
                  {
                    assessment.section_scores.map((sectionScore, index) =>

                      <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header>
                          <div className={`score-title-indicator bg-${sectionScore.score_comment}`}></div>
                          {sectionScore.assessment_template_section.name} - {sectionScore.score}/10

                        </Accordion.Header>

                        <Accordion.Body>
                          {
                            assessmentAudited && audit ?
                              <SectionRecommendationForm
                                assessment={assessment}
                                sectionScore={sectionScore}
                              />
                              :
                              sectionScore.recommendations && assessment.audit_status === 3 ?
                                <div dangerouslySetInnerHTML={{ __html: sectionScore.recommendations }}/>
                                :
                                <p>{t('pages.assessments.report.no_recommendations')}</p>
                          }
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                  }
                </Accordion>
              </Row>
            </>
            : null
        }

      </div>
    </>
  )
}

export default Report