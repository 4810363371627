function Printer(){
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1211_15052)">
        <path d="M3.125 10C3.29076 10 3.44973 9.93415 3.56694 9.81694C3.68415 9.69973 3.75 9.54076 3.75 9.375C3.75 9.20924 3.68415 9.05027 3.56694 8.93306C3.44973 8.81585 3.29076 8.75 3.125 8.75C2.95924 8.75 2.80027 8.81585 2.68306 8.93306C2.56585 9.05027 2.5 9.20924 2.5 9.375C2.5 9.54076 2.56585 9.69973 2.68306 9.81694C2.80027 9.93415 2.95924 10 3.125 10Z" fill="#FF5D17"/>
        <path d="M6.25 1.25C5.58696 1.25 4.95107 1.51339 4.48223 1.98223C4.01339 2.45107 3.75 3.08696 3.75 3.75V6.25H2.5C1.83696 6.25 1.20107 6.51339 0.732233 6.98223C0.263392 7.45107 0 8.08696 0 8.75L0 12.5C0 13.163 0.263392 13.7989 0.732233 14.2678C1.20107 14.7366 1.83696 15 2.5 15H3.75V16.25C3.75 16.913 4.01339 17.5489 4.48223 18.0178C4.95107 18.4866 5.58696 18.75 6.25 18.75H13.75C14.413 18.75 15.0489 18.4866 15.5178 18.0178C15.9866 17.5489 16.25 16.913 16.25 16.25V15H17.5C18.163 15 18.7989 14.7366 19.2678 14.2678C19.7366 13.7989 20 13.163 20 12.5V8.75C20 8.08696 19.7366 7.45107 19.2678 6.98223C18.7989 6.51339 18.163 6.25 17.5 6.25H16.25V3.75C16.25 3.08696 15.9866 2.45107 15.5178 1.98223C15.0489 1.51339 14.413 1.25 13.75 1.25H6.25ZM5 3.75C5 3.41848 5.1317 3.10054 5.36612 2.86612C5.60054 2.6317 5.91848 2.5 6.25 2.5H13.75C14.0815 2.5 14.3995 2.6317 14.6339 2.86612C14.8683 3.10054 15 3.41848 15 3.75V6.25H5V3.75ZM6.25 10C5.58696 10 4.95107 10.2634 4.48223 10.7322C4.01339 11.2011 3.75 11.837 3.75 12.5V13.75H2.5C2.16848 13.75 1.85054 13.6183 1.61612 13.3839C1.3817 13.1495 1.25 12.8315 1.25 12.5V8.75C1.25 8.41848 1.3817 8.10054 1.61612 7.86612C1.85054 7.6317 2.16848 7.5 2.5 7.5H17.5C17.8315 7.5 18.1495 7.6317 18.3839 7.86612C18.6183 8.10054 18.75 8.41848 18.75 8.75V12.5C18.75 12.8315 18.6183 13.1495 18.3839 13.3839C18.1495 13.6183 17.8315 13.75 17.5 13.75H16.25V12.5C16.25 11.837 15.9866 11.2011 15.5178 10.7322C15.0489 10.2634 14.413 10 13.75 10H6.25ZM15 12.5V16.25C15 16.5815 14.8683 16.8995 14.6339 17.1339C14.3995 17.3683 14.0815 17.5 13.75 17.5H6.25C5.91848 17.5 5.60054 17.3683 5.36612 17.1339C5.1317 16.8995 5 16.5815 5 16.25V12.5C5 12.1685 5.1317 11.8505 5.36612 11.6161C5.60054 11.3817 5.91848 11.25 6.25 11.25H13.75C14.0815 11.25 14.3995 11.3817 14.6339 11.6161C14.8683 11.8505 15 12.1685 15 12.5Z" fill="#FF5D17"/>
      </g>
      <defs>
        <clipPath id="clip0_1211_15052">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}
export default Printer;