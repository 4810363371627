
import LOCATION from '@/constants/Location'
import MyAccount from '@/views/pages/MyAccount'

const Account = [
  {
    path: LOCATION.My_ACCOUNT.LIST.path,
    component: MyAccount,
    exact: true,
    translation_key: 'pages.my_account.title',
    main: true,
    gate: 'my_account',
  }
]
export default Account;