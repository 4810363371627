import React, { useEffect, useState } from 'react'
import { Button, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Plus from '@/components/icons/Plus'
import BranchUsersForm from './BranchUsersForm'
import LoadingCover from '@/components/LoadingCover'

function OrganizationBranchForm ({
  setValue,
  watch,
  register,
  organization,
  errors,
  unregister,
}) {
  const { t } = useTranslation()

  const [organizationBranches, setOrganizationBranches] = useState([])
  const organizationBranch = {
    id: '',
    name: null,
    mobile_number: null,
    phone_number: null,
    email: null,
    website: null,
    users: [
      {
        id: '',
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
      }
    ],
  }

  const addNewBranch = () => {
    let branches = organizationBranches
    branches.push(organizationBranch)
    setOrganizationBranches([].concat(branches))
  }

  const removeBranch = (indexToRemove) => {
    let branches = organizationBranches;
    branches[indexToRemove] = null;
    setOrganizationBranches([].concat(branches))
    unregister(`branches[${indexToRemove}]`)
  }

  useEffect(() => {
    if (organization && organization?.branches && organization?.branches.length !== 0) {
      setOrganizationBranches(organization.branches)
    } else {
      // setOrganizationBranches([organizationBranch])
    }
  }, [organization])

  return (
    <div className="mt-4">
      <h6 className="">{t('pages.my_account.organization_branches')}</h6>
      {
        organizationBranches.map((organizationBranch, index) => {
          return organizationBranch ? (
            <div key={index}>
              <hr/>
              <Row className="mt-2">
                <input
                  type="hidden"
                  name="id"
                  value={organizationBranch.id}
                  {...register(`branches[${index}].id`)}
                />
                <Form.Group className="col col-sm-6 col-md-3 col-12 mt-3" controlId="formBasicBranchName">
                  <Form.Label>
                    {t('common.form.branch_name')}
                  </Form.Label>
                  <Form.Control
                    placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.branch_name') })}
                    {...register(`branches[${index}].name`)}
                    isInvalid={!!(errors.branches && errors.branches[index] && errors.branches[index].name)}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.branches && errors.branches[index] && errors.branches[index].name && errors.branches[index].name.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="col col-sm-6 col-md-3 col-12 mt-3" controlId="formBasicPhoneNumber">
                  <Form.Label>
                    {t('common.form.phone_number')}
                  </Form.Label>
                  <Form.Control
                    placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.phone_number') })}
                    {...register(`branches[${index}].phone_number`)}
                    isInvalid={!!(errors.branches && errors.branches[index] && errors.branches[index].phone_number)}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.branches && errors.branches[index] && errors.branches[index].phone_number && errors.branches[index].phone_number.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="col col-sm-6 col-md-3 col-12 mt-3" controlId="formBasicMobilePhoneNumber">
                  <Form.Label>
                    {t('common.form.mobile_number')}
                  </Form.Label>
                  <Form.Control
                    placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.mobile_number') })}
                    {...register(`branches[${index}].mobile_number`)}
                    isInvalid={!!(errors.branches && errors.branches[index] && errors.branches[index].mobile_number)}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.branches && errors.branches[index] && errors.branches[index].mobile_number && errors.branches[index].mobile_number.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="col col-sm-6 col-md-3 col-12 mt-3" controlId="formBasicEmail">
                  <Form.Label>
                    {t('common.form.email')}
                  </Form.Label>
                  <Form.Control
                    placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.email') })}
                    {...register(`branches[${index}].email`)}
                    isInvalid={!!(errors.branches && errors.branches[index] && errors.branches[index].email)}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.branches && errors.branches[index] && errors.branches[index].email && errors.branches[index].email.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="col col-sm-6 col-md-3 col-12 mt-3" controlId="formBasicWebsite">
                  <Form.Label>
                    {t('common.form.website')}
                  </Form.Label>
                  <Form.Control
                    placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.website') })}
                    {...register(`branches[${index}].website`)}
                    isInvalid={!!(errors.branches && errors.branches[index] && errors.branches[index].website)}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.branches && errors.branches[index] && errors.branches[index].website && errors.branches[index].website.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <BranchUsersForm
                branch={organizationBranch}
                setValue={setValue}
                register={register}
                unregister={unregister}
                watch={watch}
                errors={errors?.branches && errors?.branches[index] ? errors.branches[index].users : null}
                namePrefix={`branches[${index}].users`}
              />
              <Button
                variant="link" onClick={() => removeBranch(index)}
                className="mx-0 px-0 my-2"
              >
                {t('common.remove', { attribute: t('common.branch') })}
              </Button>
            </div>
          ): null;
        })
      }

      <div className="d-grid gap-2 mt-4">
        <Button
          className="text-white"
          onClick={() => addNewBranch()}
        >
          <span className="me-1">{t('pages.my_account.buttons.add_new_branch')}</span>
          <Plus/>
        </Button>
      </div>
    </div>
  )
}

export default OrganizationBranchForm