import { Button, Card, Toast, ToastContainer } from 'react-bootstrap'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BackButton from '@/views/components/Buttons/BackButton'
import { useParams } from 'react-router-dom'
import { useAuth } from '@/services/Auth'
import LoadingCover from '@/components/LoadingCover'
import LOCATION from '@/constants/Location'
import ReportSection from '@/views/components/assessments/ReportSection'
import PrintButton from '@/views/components/Buttons/PrintButton'
import DownloadButton from '@/views/components/Buttons/DownloadButton'
import PageActionSection from '@/components/PageActionSection'
import LoadingButton from '../../../components/LoadingButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faShare, faShareAlt } from '@fortawesome/free-solid-svg-icons'

function FullReport () {
  const { t } = useTranslation()
  const auth = useAuth()
  let { assessment_id, reference_number, share_code } = useParams()

  const [loading, setLoading] = useState(true)
  const [generatingLink, setGeneratingLink] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [assessment, setAssessment] = useState(null)

  const reportTemplateRef = useRef(null)

  const generateLink = () => {
    setGeneratingLink(true)
    auth.getRequest(`${LOCATION.ASSESSMENTS.API.path}/${assessment_id}/share-link`)
      .then(response => {
        setAssessment(response.data)
        setGeneratingLink(false)
      })
      .catch(error => {
      })
  }

  const copyShareLink = () => {
    const shareLink = `${process.env.REACT_APP_URL}${LOCATION.ASSESSMENTS.API.path}/${assessment?.fra_reference_number}/${assessment.share_code}`
    navigator.clipboard.writeText(shareLink)
    setShowToast(true)
  }

  const getAssessmentById = () => {
    let request = auth.getRequest(`${LOCATION.ASSESSMENTS.API.path}/${assessment_id}`)
    request
      .then(response => {
        setAssessment(response.data)
        setLoading(false)
      })
      .catch(error => {
      })

    return request
  }

  const getShareAssessment = () => {
    let request = auth.getRequest(`${LOCATION.ASSESSMENTS.API.path}/shared/${reference_number}/${share_code}`)
    request
      .then(response => {
        setAssessment(response.data)
        setLoading(false)
      })
      .catch(error => {
      })

    return request
  }

  useEffect(() => {
    let request
    if (assessment_id) {
      request = getAssessmentById()
    } else if (reference_number && share_code) {
      request = getShareAssessment()
    }

    return () => request?.abort && request.abort()
  }, [])

  if (loading || !assessment) {
    return (
      <LoadingCover/>
    )
  }

  return (
    <>

      <Toast bg="info" className="position-fixed" style={{ top: '10px', right: '10px', zIndex: '9999' }}
             show={showToast} autohide onClose={() => setShowToast(false)} delay={3000}>
        <Toast.Body>{t('pages.assessments.notifications.share_link_copied')}</Toast.Body>
      </Toast>

      <PageActionSection
        prepend={[auth.user ? <BackButton fillColor="white"/> : null]}
        append={
          [
            assessment?.share_code && auth.user ?
              <Button size="sm" variant="light" className="text-primary" onClick={() => copyShareLink()}>
                <span className="me-1">{t('pages.assessments.buttons.copy_share_link')}</span>
                <FontAwesomeIcon size="sm" icon={faCopy}/>
              </Button>
              :
              auth.user ?
                <LoadingButton
                  loading={generatingLink}
                  onSubmit={generateLink}
                  variant="light"
                  className="text-primary btn-sm"
                  titleTranslationKey="pages.assessments.buttons.generate_share_link"
                  type="button"
                  icon={<FontAwesomeIcon size="sm" icon={faShare}/>}
                  size="sm"
                /> : null,
            <PrintButton elementName="full-report"/>,
            <DownloadButton
              element="full-report"
              multiplePages={true}
              fileName={assessment?.assessment_template.title}
              scale={0.5}
            />
          ]}
        title={t('pages.assessments.report.full_report')}
        type="primary"
      />
      <div className="container full-report-container my-4" id="full-report">
        <Card id="full-report" className="border-0 " ref={reportTemplateRef}>
          <Card.Body>
            {
              assessment.assessment_template.assessment_sections.map((assessmentSection, index) =>
                <ReportSection
                  key={assessmentSection.id}
                  assessment={assessment}
                  sectionIndex={index}
                  assessmentSection={assessmentSection}
                />
              )
            }
          </Card.Body>
        </Card>
      </div>
    </>
  )
}

export default FullReport