import { Button, Form, Row } from 'react-bootstrap'
import React from 'react'
import { useTranslation } from 'react-i18next'

function ServiceForm ({
  services,
  namePrefix,
  errors,
  serviceIndex,
  removeService,
  register,
}) {

  const { t } = useTranslation()

  return (
    <Row>
      <Form.Group className="col col-md-3 col-6 mt-3" controlId="formBasicCompanyName">
        <Form.Label>
          {t('common.form.service_name')}
        </Form.Label>
        <Form.Control
          placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.service_name') })}
          {...register(`${namePrefix}.name`)}
          isInvalid={!!(errors && errors[serviceIndex] && errors[serviceIndex].name)}
        />

        <Form.Control.Feedback type="invalid">
          {errors && errors[serviceIndex] && errors[serviceIndex].name && errors[serviceIndex].name.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="col col-md-3 col-6 mt-3" controlId="formBasicRegistrationNumber">
        <Form.Label>
          {t('common.form.service_price')}
        </Form.Label>
        <Form.Control
          type="number"
          placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.service_price') })}
          {...register(`${namePrefix}.price`)}
          isInvalid={!!(errors && errors[serviceIndex] && errors[serviceIndex].price)}
        />

        <Form.Control.Feedback type="invalid">
          {errors && errors[serviceIndex] && errors[serviceIndex].price && errors[serviceIndex].price.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="col col-md-3 col-6 mt-3" controlId="formBasicRegistrationNumber">
        <Form.Label>
          {t('common.form.rate')}
        </Form.Label>
        <Form.Control
          as="select"
          placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.rate') })}
          {...register(`${namePrefix}.rate`)}
          isInvalid={!!(errors && errors[serviceIndex] && errors[serviceIndex].rate)}
        >
          <option value="hour">{t('pages.my_account.rates.per_hour')}</option>
          <option value="day">{t('pages.my_account.rates.per_day')}</option>
        </Form.Control>

        <Form.Control.Feedback type="invalid">
          {errors && errors[serviceIndex] && errors[serviceIndex].rate && errors[serviceIndex].rate.message}
        </Form.Control.Feedback>
      </Form.Group>
      {
        services.length > 1 ?
          <Form.Group className="col col-md-3 col-6 mt-3 d-flex align-items-end" controlId="formBasicRegistrationNumber">
            <Button className="px-0" variant="link" onClick={() => removeService(serviceIndex)}>
              <span className="me-1">{t('pages.my_account.buttons.remove_service')}</span>
            </Button>
          </Form.Group>
          : null
      }
    </Row>
  )
}

export default ServiceForm