import { useEffect, useState } from 'react'

function ArrowRight ({ fill }) {

  const [colorFill, setColorFill] = useState('white')

  useEffect(() => {
    if (fill === 'primary') {
      setColorFill(getComputedStyle(document.documentElement).getPropertyValue('--bs-primary'))
    }
    else if (fill === 'success') {
      setColorFill(getComputedStyle(document.documentElement).getPropertyValue('--bs-success'))
    }
    else if (fill === 'danger') {
      setColorFill(getComputedStyle(document.documentElement).getPropertyValue('--bs-danger'))
    }

  }, [fill])
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M15.2189 6.96901C15.2885 6.89917 15.3713 6.84375 15.4624 6.80594C15.5535 6.76813 15.6512 6.74867 15.7499 6.74867C15.8485 6.74867 15.9462 6.76813 16.0373 6.80594C16.1284 6.84375 16.2112 6.89917 16.2809 6.96901L20.7809 11.469C20.8507 11.5387 20.9061 11.6214 20.9439 11.7126C20.9818 11.8037 21.0012 11.9014 21.0012 12C21.0012 12.0987 20.9818 12.1963 20.9439 12.2875C20.9061 12.3786 20.8507 12.4613 20.7809 12.531L16.2809 17.031C16.14 17.1718 15.949 17.251 15.7499 17.251C15.5507 17.251 15.3597 17.1718 15.2189 17.031C15.078 16.8902 14.9989 16.6992 14.9989 16.5C14.9989 16.3008 15.078 16.1098 15.2189 15.969L19.1894 12L15.2189 8.03101C15.149 7.96134 15.0936 7.87858 15.0558 7.78746C15.018 7.69635 14.9985 7.59866 14.9985 7.50001C14.9985 7.40136 15.018 7.30368 15.0558 7.21256C15.0936 7.12144 15.149 7.03868 15.2189 6.96901Z"
            fill={colorFill}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M3 12C3 11.8011 3.07902 11.6103 3.21967 11.4697C3.36032 11.329 3.55109 11.25 3.75 11.25H19.5C19.6989 11.25 19.8897 11.329 20.0303 11.4697C20.171 11.6103 20.25 11.8011 20.25 12C20.25 12.1989 20.171 12.3897 20.0303 12.5303C19.8897 12.671 19.6989 12.75 19.5 12.75H3.75C3.55109 12.75 3.36032 12.671 3.21967 12.5303C3.07902 12.3897 3 12.1989 3 12Z"
            fill={colorFill}/>
    </svg>
  )
}

export default ArrowRight