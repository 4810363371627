import Logo from "@/components/Logo";
import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {useAuth} from "@/services/Auth";
import { Button, Dropdown } from 'react-bootstrap'
import BarsIcon from '../../components/icons/BarsIcon'

function Navbar({handleToggleSidebar, toggled}) {
    const auth = useAuth();
    const {t} = useTranslation();
    return (
        <nav className="main-navbar navbar fixed-top navbar-light bg-white p-0 m-0">
            <div className="container-fluid px-4">
                <div className="d-flex align-items-center">
                    {
                        auth.user &&
                      <Button size="sm" variant="light" className="text-primary border-0 me-2 d-xl-none d-block" onClick={() => handleToggleSidebar(!toggled)}>
                          <BarsIcon/>
                      </Button>
                    }
                    <a href="/" className="navbar-brand">
                        <Logo/>
                    </a>
                </div>
                <div className="navbar-nav flex-row align-items-center d-md-flex d-none">
                    <a className="nav-link mx-2 px-3" href="#">Features</a>
                    <Link className="nav-link mx-2 px-3" to="/pricing-and-plans">Pricing & Plans</Link>
                    <Link className="nav-link mx-2 px-3" to="/our-network">{t('pages.our_network.title')}</Link>
                    <a className="nav-link mx-2 px-3" href="#">Get in Touch</a>
                    {
                        !auth.user &&
                        <>
                            <Link to="/login" className="btn btn-outline-dark btn-sm mx-2 px-2">{t('pages.login.title')}</Link>
                            <Link to="/sign-up" className="btn btn-primary text-white btn-sm mx-2 px-2">{t('pages.signup.title')}</Link>
                        </>
                    }
                </div>

                <Dropdown align="end" className="me-2  d-md-none d-block">
                    <Dropdown.Toggle className="btn-light p-3">
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item> <a className="nav-link mx-2 px-2" href="#">Features</a></Dropdown.Item>
                        <Dropdown.Item><Link className="nav-link mx-2 px-2" to="/pricing-and-plans">Pricing & Plans</Link></Dropdown.Item>
                        <Dropdown.Item><a className="nav-link mx-2 px-2" href="#">Get in Touch</a></Dropdown.Item>

                        {
                          !auth.user &&
                          <Dropdown.Item>
                              <Link to="/login" className="btn btn-outline-dark btn-sm mx-2 px-2">{t('pages.login.title')}</Link>
                          </Dropdown.Item>
                        }
                        {
                          !auth.user &&
                          <Dropdown.Item>
                              <Link to="/sign-up" className="btn btn-primary text-white btn-sm mx-2 px-2">{t('pages.signup.title')}</Link>
                          </Dropdown.Item>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </nav>
    )
}

export default Navbar
