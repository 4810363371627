const common = {
  go_home: 'Go Home',
  submit: 'Submit',
  logout: 'Log Out',
  save: 'Save',
  retry: 'Retry',
  user: 'User',
  print: 'Print',
  download: 'Download',
  terms_and_condition: 'Terms and Conditions',
  please_wait: 'Please wait...',
  feature_image_size: 'Required Size {{size}}',
  choose: 'Choose',
  choices: 'Choices',
  scores: 'Scores',
  correct_answer: 'Correct Answer',
  ratings: 'Ratings',
  high: 'High',
  medium: 'Medium',
  low: 'Low',
  add: 'Add {{attribute}}',
  add_other: 'Add Other {{attribute}}',
  remove: 'Remove {{attribute}}',
  search: 'Search',
  assessment: 'Assessment',
  status: 'Status',
  assessment_date_between: 'Assessment Date Between',
  search_for_anything: 'Search For Anything',
  apply: 'Apply',
  export: 'Export',
  update: 'Update',
  loading: 'Loading...',
  no_data: 'No Data',
  score: 'Score',
  branch: 'Branch',
  okay: 'Okay',
  visit_website: 'Visit Website',
  rate_per: 'Per {{rate}}',
  features: 'Features',
  card: 'Card',
  expires: 'Expires',
  none: 'None',
  price: '{{currency}}{{price}}',
  form: {
    address: 'Address',
    responsible_person: 'Full name of the person responsible for the property',
    where_is_your_property_located: 'Where is your property located?',
    placeholder_enter: 'Enter {{attribute}}',
    placeholder_choose: 'Choose {{attribute}}',
    password: 'Password',
    email: 'Email Address',
    first_name: 'First Name',
    last_name: 'Last Name',
    email_placeholder: 'Enter Email Address',
    phone_number_placeholder: 'Enter Phone Number',
    first_name_placeholder: 'Enter First Name',
    last_name_placeholder: 'Enter Last Name',
    keep_me_signed_in: 'Keep me signed in',
    re_enter_password: 'Re-enter Password',
    phone_number: 'Phone Number',
    title: 'Title',
    title_placeholder: 'Enter Title',
    description: 'Description',
    description_placeholder: 'Enter Description',
    featured_image: 'Featured Image',
    featured_image_placeholder: 'Choose Featured Image',
    section_name: 'Section Name',
    section_name_placeholder: 'Enter Section Name',
    weight: 'Weight',
    weight_placeholder: 'Enter Section Weight',
    field_type: 'Field Type',
    field_label: 'Field Label',
    required: 'Required',
    help_text: 'Help Text',
    has_score: 'Has Score',
    select_multiple: 'Multiple',
    answer: 'Answer',
    score: 'Score',
    add_other: 'Add Other',
    field_description: 'Field Description',
    range_title: 'Range Title',
    this_question: 'This question',
    fra_reference_number: 'FRA Reference No.',
    your_message: 'Your Message',
    message: 'Message',
    type_here: 'Type Here...',
    name: 'Name',
    section_recommendation: 'Section recommendation',
    serial_number: 'Serial Number',
    company_name: 'Company Name',
    company_description: 'Description of Company',
    external_page_url: 'External Company Page to Link to',
    branch_name: 'Branch Name',
    registration_number: 'Registration Number',
    mobile_number: 'Mobile Number',
    website: 'Website',
    payment_method: 'Payment Method',
    expire_date: 'Expire Date',
    renew_type: 'Renew Type',
    renew_date: 'Renew Date',
    min_slider_value: 'Min Slider Value',
    max_slider_value: 'Max Slider Value',
    question_score: 'FRA Score (0-10)',
    max_total_input_value: 'Max total input values',
    is_other: 'Is Other',
    field_custom_rating: '{{attribute}} Custom rating (0-10)',
    rating_label: '{{attribute}} Rating (0-10)',
    range_suffix: 'Range Suffix',
    correct_answer: 'Correct Answer',
    service_name: 'Service Name',
    service_price: 'Service Price',
    rate: 'Rate',
  },
  form_validation: {
    should_be_a_number: '{{attribute}} should be a number',
    is_required: '{{attribute}} is required',
    is_required_choose: 'Please choose {{attribute}}',
    must_be_a_number: '{{attribute}} must be number',
    first_name_is_required: 'First name is required',
    last_name_is_required: 'Last name is required',
    email_address_is_required: 'Email address is required',
    invalid_email_address: 'Please use valid email address',
    invalid_url: 'Please use valid website url',
    password_is_required: 'Password is required',
    password_invalid_min_length: 'Password must not be less than {{length}} characters',
    password_is_must_match: 'Password must match',
    password_confirm_required: 'Password confirm required',
    phone_number_is_required: 'Phone number is required',
    title_is_required: 'Title is required',
    description_is_required: 'Description is required',
    featured_image_is_required: 'Featured image is required',
    max: '{{field}} value should not be more than {{max}}'
  },
  buttons: {
    remove: 'Remove',
    back: 'Back',
    continue_to_payment: 'Continue to Payment',
    view_details: 'View Details',
    delete: 'Delete',
  },
  tables: {
    assessment_name: 'Assessment Name',
    assessment_date: 'Date',
  },
  formats: {
    date: 'Do MMM YY',
    short_date: 'DD/MM/YYYY',
    datetime: 'Do MMM YY HH:mm',
    date_no_time: 'DD MMMM, YYYY',
    short_datetime: 'DD/MM/YY HH:mm'
  },
  status_list: {
    not_started: 'Not Started',
    completed: 'Completed',
    audit_replied: 'Audit Replied',
    in_progress: 'In Progress',
    audit_requested: 'Audit Requested',
  },
  tokens: {
    title: 'Credits',
    remaining: '{{count}} Remaining',
    used: '{{count}} Used',
    unlimited: 'Unlimited',
  },
  errors: {
    invalid_credentials: 'Invalid Credentials',
    invalid_token: 'Invalid Token!',
    organization: {
      title: 'Missing Organization Details',
      message: 'Please set up organization details to continue',
    },
    subscription_exists: {
      title: 'You have an active subscription',
      message: 'You currently have an active subscription and you can\'t subscribe again, if you want to change your subscription you have to cancel the current one first',
    },
    buttons: {
      set_organization: 'Set Organization Details'
    },
    titles: {
      error500: '500 | SERVER ERROR',
      error404: '404 | PAGE NOT FOUND',
      error403: '403 | UNAUTHORIZED REQUEST',
      connection_error: 'No internet',
    },
    body: {
      error500: 'Sorry, this page could not be loaded',
      error404: 'Sorry, the page you are looking for does not exist, have been removed, name changed or is temporary unavailable',
      error403: 'Sorry, you are not authorized to access the page you requested',
      connection_error: 'We were not able to establish a working connection, please make sure your device is connection to the internet then retry',
    }
  },
  dialogs: {
    titles: {
      are_you_sure: 'Are you sure?',
      update_success: 'Updated Successful!',
      subscription_cancelled: 'Subscription Cancelled',
      organization_updated: 'Organization Updated',
      organization_created: 'Organization Created',
      companies_updated: 'Companies Updated',
      success: 'Success',
    },
    body: {
      cancel_subscription: 'Do you want to cancel your subscription?',
    },
    actions: {
      okay: 'Okay',
      remove: 'Remove',
      delete: 'Delete',
      cancel: 'Cancel',
      no: 'No',
      yes: 'Yes',
    },
  },
  notifications: {
    listing: {
      no_list_found: {
        title: 'No Listing found!',
        body: 'Unfortunately no listing was found from our network'
      }
    },
    subscribed: {
      title: 'Subscribed',
      body: 'You have successful subscribed receive {{credits}} to use on any service on your every {{duration}} renewal',
    }
  }
}
export default common
