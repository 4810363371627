import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { Button, Col, Form, Row } from 'react-bootstrap'
import * as moment from 'moment/moment'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef } from 'react'
import LoadingButton from '../../components/LoadingButton'

function AssessmentFilters ({
  values,
  assessmentTemplates,
  assessmentStatus,
  loadAssessments,
  searching,
  hideSearch,
}) {

  const submitFilter = useRef(null)

  const { t } = useTranslation()
  const {
    resetField,
    handleSubmit,
    register,
    setValue,
    watch,
    unregister,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...values
    }
  })

  const submitButton = () => {
    return <LoadingButton
      type="submit"
      onlySpinner={true}
      loading={searching}
      className="text-white"
      titleTranslationKey="common.apply"
    />
  }

  const startDate = watch('start_date')

  const onSubmit = (values) => {
    loadAssessments(values)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        {
          !hideSearch &&
          <Col className="col-md-3 col-sm-6 col-12">
            <Form.Group>
              <Form.Label>
                {t('common.search')}
              </Form.Label>
              <Form.Control
                {...register('search')}
                placeholder={t('common.search_for_anything')}
                name="search"
              />
            </Form.Group>
          </Col>
        }

        <Col className={`${!hideSearch ? 'col-md-2' : 'col-lg-6 col-xl-3'} col-12 col-sm-6`}>
          <Form.Group>
            <Form.Label>
              {t('common.assessment')}
            </Form.Label>
            <Form.Control
              {...register('assessment_template_id')}
              as="select"
              aria-label={t('common.assessment')}
              name="assessment_template_id"
            >
              <option value="" hidden>{t('common.choose')}</option>
              {
                assessmentTemplates.map(assessmentTemplate =>
                  <option
                    key={assessmentTemplate.id}
                    value={assessmentTemplate.id}
                  >
                    {assessmentTemplate.title}
                  </option>
                )
              }
            </Form.Control>
          </Form.Group>

        </Col>
        <Col className={`${!hideSearch ? 'col-md-2' : 'col-lg-6 col-xl-3'} col-12 col-sm-6`}>
          <Form.Group>
            <Form.Label>
              {t('common.status')}
            </Form.Label>

            <Form.Control
              as="select"
              name="status"
              {...register('status')}
              aria-label={t('common.status')}
            >
              <option value="" hidden>{t('common.choose')}</option>
              {
                assessmentStatus.map(status =>
                  <option
                    key={status.id}
                    value={status.id}
                  >
                    {status.name}
                  </option>
                )
              }
            </Form.Control>
          </Form.Group>
        </Col>
        <Col className={`${!hideSearch ? 'col-md-5' : 'col-lg-12 col-xl-6'} col-12`}>
          <Form.Group>
            <Form.Label>
              {t('common.assessment_date_between')}
            </Form.Label>
            <Row>
              <Col className="col-md-10 col-12">
                <div className="d-flex">
                  <Form.Control
                    className="me-3"
                    type="date"
                    name="start_date"
                    {...register('start_date')}
                    placeholder={t('common.start_date')}
                  />
                  <Form.Control
                    disabled={!startDate}
                    className="ms-2"
                    type="date"
                    name="end_date"
                    placeholder={t('common.end_date')}
                    {...register('end_date')}
                    min={moment(startDate).add(1, 'day').format('YYYY-MM-DD')}
                  />
                </div>
              </Col>

              <Col className="d-none d-md-inline col-md-2 col-lg-2 col-12">
                <div className="d-grid gap-2">
                  {submitButton()}
                </div>
              </Col>
            </Row>
          </Form.Group>
        </Col>
        <Col className="d-md-none col-12 mt-3">
          <div className="d-grid gap-2">
            {submitButton()}
          </div>
        </Col>
      </Row>
    </form>
  )
}

export default AssessmentFilters