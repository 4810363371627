import { useTranslation } from 'react-i18next'
import { Button } from 'react-bootstrap'
import React from 'react'
import ArrowLeft from '@/components/icons/ArrowLeft'
import { useHistory } from 'react-router-dom'

function BackButton({variant, fillColor}){
  let history = useHistory();

  const onBackButton =() => {
    history.goBack();
  }

  const { t } = useTranslation()
  return (
    <Button variant={`${variant ?? 'primary' }`} onClick={() => onBackButton()} size='sm'>
      <ArrowLeft
        fillColor={fillColor ?? '#FE5D18'}
      />
      <span className="ms-1">{t('common.buttons.back')}</span>
    </Button>
  )
}

export default BackButton;