import LOCATION from '@/constants/Location'
import Create from '@/views/pages/Assessments/Create'
import Assessments from '@/views/pages/Assessments'
import AssessmentForm from '@/views/pages/Assessments/Forms/AssessmentForm'
import Report from '@/views/pages/Assessments/Report'
import RequestAudit from '@/views/pages/Assessments/RequestAudit/RequestAudit'
import Certificate from '@/views/pages/Assessments/Certificate'
import FullReport from '@/views/pages/Assessments/FullReport'
import SendAuditReport from '@/views/pages/AssessmentAudit/SendAuditReport'
import AuditPaymentScreen from '@/views/pages/Assessments/RequestAudit/AuditPaymentScreen'
import AuditRequestSuccess from '@/views/pages/Assessments/RequestAudit/AuditRequestSuccess'

const AssessmentRoutes = [
  {
    path: LOCATION.ASSESSMENTS.LIST.path,
    component: Assessments,
    exact: true,
    translation_key: 'pages.my_assessments.title',
    main: true,
    gate: 'my_assessments',
  },
  {
    path: LOCATION.ASSESSMENTS.CREATE.path,
    component: Create,
    exact: true,
    translation_key: 'pages.my_assessments.new_assessment',
    main: true,
    gate: 'my_assessments',
  },
  {
    path: LOCATION.ASSESSMENTS.FORM.path,
    component: AssessmentForm,
    exact: true,
    translation_key: 'pages.my_assessments.new_assessment',
    main: false,
    gate: 'my_assessments',
  },
  {
    path: LOCATION.ASSESSMENTS.EDIT_FORM.path,
    component: AssessmentForm,
    exact: true,
    translation_key: 'pages.my_assessments.new_assessment',
    main: false,
    gate: 'my_assessments',
  },
  {
    path: LOCATION.ASSESSMENTS.REPORT.path,
    component: Report,
    exact: true,
    translation_key: 'pages.my_assessments.new_assessment',
    main: false,
    gate: 'assessment_report',
  },
  {
    path: LOCATION.ASSESSMENTS.REPORT_AUDIT.path,
    component: Report,
    exact: true,
    translation_key: 'pages.my_assessments.new_assessment',
    main: false,
    gate: 'assessment_report',
  },
  {
    path: LOCATION.ASSESSMENTS.AUDIT_REQUEST.path,
    component: RequestAudit,
    exact: true,
    translation_key: 'pages.my_assessments.new_assessment',
    main: false,
    gate: 'my_assessments',
  },
  {
    path: LOCATION.ASSESSMENTS.AUDIT_REQUEST_PAYMENT.path,
    component: AuditPaymentScreen,
    exact: true,
    translation_key: 'pages.my_assessments.new_assessment',
    main: false,
    gate: 'my_assessments',
  },
  {
    path: LOCATION.ASSESSMENTS.AUDIT_REQUEST_SUCCESS.path,
    component: AuditRequestSuccess,
    exact: true,
    translation_key: 'pages.my_assessments.new_assessment',
    main: false,
    gate: 'my_assessments',
  },
  {
    path: LOCATION.ASSESSMENTS.SEND_AUDIT_REPORT.path,
    component: SendAuditReport,
    exact: true,
    translation_key: 'pages.my_assessments.new_assessment',
    main: false,
    gate: 'assessment_report',
  },
  {
    path: LOCATION.ASSESSMENTS.CERTIFICATE.path,
    component: Certificate,
    exact: true,
    translation_key: 'pages.my_assessments.new_assessment',
    main: false,
    gate: 'assessment_report',
  },
  {
    path: LOCATION.ASSESSMENTS.CERTIFICATE_AUDIT.path,
    component: Certificate,
    exact: true,
    translation_key: 'pages.my_assessments.new_assessment',
    main: false,
    gate: 'assessment_report',
  },
  {
    path: LOCATION.ASSESSMENTS.FULL_REPORT.path,
    component: FullReport,
    exact: true,
    translation_key: 'pages.my_assessments.new_assessment',
    main: false,
    gate: 'assessment_report',
  },
]
export default AssessmentRoutes