import AssessmentForm from '../Assessments/Forms/AssessmentForm'

export function Audit () {

  let auditMode = true;

  return (
    <AssessmentForm
      auditMode={auditMode}
    />
  )
}
export default Audit;