import Private from './Private'
import Public from './Public'
import Error404 from '../views/errors/Error404'

const routes = [
  ...Private,
  ...Public,
  {
    path: '*',
    component: Error404,
    exact: true,
    translation_key: 'common.dashboard',
    main: false,
  },
]

export default routes
