import React, { useState } from 'react'
import LoadingButton from '@/components/LoadingButton'
import ConfirmModal from '@/components/modals/ConfirmModal'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'
import { useTranslation } from 'react-i18next'

function CancelSubscription ({ id, getSubscription }) {

  const auth = useAuth()

  const { t } = useTranslation()

  const [showSuccess, setShowSuccess] = useState(false)
  const [title, setTitle] = useState('')
  const [modalBody, setModalBody] = useState(null)
  const [confirmText, setConfirmText] = useState(null)
  const [cancelText, setCancelText] = useState(null)

  const [cancelingSubscription, setCancelingSubscription] = useState(false)
  const [requestCancel, setRequestCancel] = useState(false)

  const requestSubscriptionCancel = () => {
    setTitle(t('common.dialogs.titles.are_you_sure'))
    setModalBody(t('common.dialogs.body.cancel_subscription'))
    setCancelText(t('common.dialogs.actions.no'))
    setConfirmText(t('common.dialogs.actions.yes'))
    setShowSuccess(true)
    setRequestCancel(true)
  }
  const cancelSubscription = () => {
    setCancelingSubscription(true)
    auth.deleteRequest(`${LOCATION.SUBSCRIPTIONS.API.path}/${id}`)
      .then(response => {
        setTitle(t('common.dialogs.titles.subscription_cancelled'))
        setConfirmText(t('common.dialogs.actions.okay'))
        setShowSuccess(true)
        setCancelingSubscription(false)
        auth.checkToken().then(response => getSubscription())
      })
      .catch(error => {
        setCancelingSubscription(false)
      })
  }

  const handleConfirm = () => {
    setShowSuccess(false)
    setTitle(null)
    setModalBody(null)
    if (requestCancel) {
      cancelSubscription()
    }
  }
  const handleCancel = () => {
    setShowSuccess(false)
    setTitle(null)
    setModalBody(null)
    setCancelText(null)
  }

  return (
    <>
      <ConfirmModal
        show={showSuccess}
        title={title}
        body={modalBody}
        confirmText={confirmText}
        cancelText={cancelText}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />

      <LoadingButton
        onSubmit={requestSubscriptionCancel}
        loading={cancelingSubscription}
        className="text-white"
        variant="danger"
        titleTranslationKey="pages.plans.subscriptions.cancel"
      />
    </>
  )
}

export default CancelSubscription