const competencyTemplates = {
  title: 'Competency Checks',
  test: 'Competency Tests',
  create: 'Competency Checks',
  create_new_assessment: 'Create New Template',
  you_need_to_pass: 'You need 80% to pass',
  this_does_not_cost_any_tokes: 'This Does Not Cost Any Tokens',
  report: {
    title: 'Competency Test Result',
  },
  buttons: {
    restart_test: 'Restart Test',
    competency_certificate: 'Competency Certificate',
    save_quit: 'Save & Quit'
  }
}

export default competencyTemplates