import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { ordinalSuffixOf } from '@/Utility'
import { Image } from 'react-bootstrap'
import { useAuth } from '@/services/Auth'

function ReportSection ({ sectionIndex, assessmentSection, assessment }) {
  const { t } = useTranslation()
  return (
    <div className="my-4">
      <h5>{sectionIndex + 1}. {assessmentSection.name}</h5>
      {
        sectionIndex === 0 &&
        <>
          <div className="my-2 pb-2 pt-4">
            <h5 className="fw-bold">{t('common.form.fra_reference_number')}</h5>
            <p className="report-answers">{assessment.fra_reference_number}</p>
          </div>
          <div className="my-2 py-2">
            <h6 className="fw-bold">{t('common.form.address')}</h6>
            <p className="report-answers">{assessment.address}</p>
          </div>
        </>
      }
      {
        assessmentSection.questions.map(question =>
          <Question
            key={question.id}
            sectionIndex={sectionIndex}
            question={question}
            answers={assessment.answers}
          />
        )
      }
    </div>
  )
}

function Question ({ question, answers, sectionIndex, groupIndex, showCounter, childQuestion, otherGroupCounter }) {
  const auth = useAuth()
  const baseUrl = auth.api.defaults.baseURL

  const [answer, setAnswer] = useState(null)
  const [displayAnswer, setDisplayAnswer] = useState(null)

  const getAnswer = () => {
    let returnedAnswer = null
    returnedAnswer = answer?.answer

    if( question.select_multiple ){
      let choices = question.question_choices.filter(_ => answers.map(_=> _.question_choice_id).includes( _.id))

      returnedAnswer = choices.map(_ => {
        if (_?.other_answer) {
          return `${_?.answer} - ${_?.other_answer}`
        }
        return  _?.answer;
      }).join(', ');
    }

    else if (answer?.question_choice_id != null) {
      let choice = question.question_choices.find(_ => _.id == answer?.question_choice_id)
      if (choice) {
        returnedAnswer = choice?.answer
        if (answer?.other_answer) {
          returnedAnswer = `${choice?.answer} - ${answer?.other_answer}`
        }
      }
    }

    if ([11].includes(question.type)) {
      returnedAnswer = `${returnedAnswer}${question.description}`
    } else if ([12].includes(question.type)) {
      returnedAnswer = `${returnedAnswer} | ${question.description} - ${answer?.range_value}`
    }
    setDisplayAnswer(returnedAnswer)
  }

  useEffect(() => {
    let currentAnswer = answers.find(_ => _.question_id === question.id)

    if (otherGroupCounter) {
      let answersList = answers.filter(_ => _.question_id === question.id)
      if (answersList && answersList[otherGroupCounter]) {
        currentAnswer = answersList[otherGroupCounter]
      }
    } else if (groupIndex) {
      currentAnswer = answers.find(_ => _.question_id === question.id && _.question_index === groupIndex)
    }

    setAnswer(currentAnswer)
  }, [otherGroupCounter])

  useEffect(() => {
    getAnswer()
  }, [answer])
  return (

    <div className="my-2 py-2">
      {
        showCounter ?
          <h6>{ordinalSuffixOf(groupIndex + 1)} {question.label}</h6>
          :
          !childQuestion ?
            <h6 className="my-2 fw-bold">{question.label}</h6> :
            <h6>{`${otherGroupCounter != null ? answer?.other_answer : question.label}`}</h6>
      }
      {
        [3, 6].includes(question.type) ?
          GroupQuestion(question, answers, sectionIndex) :

          <p className="report-answers">{
            question.type === 10 ?
              displayAnswer != null ?
                <Image className="w-100" src={`${baseUrl}/${displayAnswer}`}/>
                :
                null
              :
              displayAnswer
          }
          </p>
      }
    </div>
  )
}

function GroupQuestion (question, answers, sectionIndex) {

  const [groupCounter, setGroupCounter] = useState(1)
  const [otherQuestion, setOtherQuestion] = useState(null)
  const [otherQuestions, setOtherQuestions] = useState([])

  const getQuestions = () => {
    let questions = []
    for (let counter = 0; counter < groupCounter; counter++) {
      question.children.map(childQuestion => {
        if (!childQuestion.is_other) {
          questions.push(
            <Question
              key={counter}
              showCounter={question.type === 3}
              question={childQuestion}
              groupIndex={counter}
              answers={answers}
              sectionIndex={sectionIndex}
              childQuestion={true}
            />
          )
        }
      })

      otherQuestions.map((otherQuestion, index) => {
        questions.push(
          <Question
            key={(index + question.children.length)}
            showCounter={question.type === 3}
            question={otherQuestion}
            groupIndex={index}
            otherGroupCounter={index}
            answers={answers}
            sectionIndex={sectionIndex}
            childQuestion={true}
          />
        )
      })
    }

    return questions
  }

  useEffect(() => {
    let otherQuestion = question.children.find(_ => _.is_other)
    setOtherQuestion(otherQuestion)

    if ([3].includes(question.type)) {
      question.children.forEach(childQuestion => {
        let questionAnswers = answers.filter(_ => _.question_id === childQuestion.id)
        if (questionAnswers && questionAnswers.length > groupCounter) {
          setGroupCounter((_) => questionAnswers.length)
        }
      })
    }
  }, [question])

  useEffect(() => {
    if (otherQuestion) {
      let otherQuestionAnswers = answers.filter(_ => _.question_id === otherQuestion.id)

      let questions = []
      otherQuestionAnswers.forEach(_ => {
        questions.push(otherQuestion)
      })

      setOtherQuestions([].concat(questions))
    }
  }, [otherQuestion])

  return (
    <>
      {getQuestions()}
    </>
  )
}

export default ReportSection