import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import React, { useEffect } from 'react'
import { Button, Card } from 'react-bootstrap'
import FaceFrown from '@/components/icons/FaceFrown'

function ConnectionError(){
  const {t} = useTranslation();
  let auth = useAuth();


  return (
    <div className="container request-sent d-flex justify-content-center align-items-center h-100">
      <Card className="border-0 p-5 bg-white">
        <Card.Body className="text-center">
          <FaceFrown/>
          <h3 className="mt-4">{t('common.errors.titles.connection_error')}</h3>
          <p className="my-4">{t('common.errors.body.connection_error')}</p>
          <Button onClick={() => window.location.reload()}> {t('common.retry')}</Button>
        </Card.Body>

      </Card>
    </div>
  )
}
export default ConnectionError;