import { useTranslation } from 'react-i18next'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { useAuth } from '@/services/Auth'
import LOCATION from '@/constants/Location'
import { useEffect, useState } from 'react'
import PlanCard from './components/PlanCard'

function PricingAndPlans () {
  const { t } = useTranslation()
  const auth = useAuth()

  let baseUrl = auth.api.defaults.baseURL

  let { out_of_tokens } = useParams()

  const [plans, setPlans] = useState([])

  useEffect(() => {
    let request = auth.getRequest(`${LOCATION.PLANS.API.path}`)

    request
      .then(response => {
        setPlans(response.data?.data)
      })
      .catch(error => {
      })

    return () => request?.abort && request.abort()

  }, [])

  return (
    <div className="container h-100 d-flex flex-column align-items-center flex-grow-1">
      <div className="mt-5 p-2">
        {
          out_of_tokens ?
            <h2 className="fw-bold text-primary">{t('pages.plans.select_plan_out_of_tokens')}</h2>
            :

            <h2 className="fw-bold text-primary">{t('pages.plans.select_plan')}</h2>
        }

        <p className="my-4">{t('pages.plans.token_instruction')}</p>

        <Row>
          {
            plans.map(plan =>
              <Col key={plan.id} className="col-12 col-md-6 col-lg-4">
                <PlanCard
                  plan={plan}
                  hideButton={false}
                />
              </Col>
            )
          }
        </Row>

      </div>
    </div>
  )
}

export default PricingAndPlans